import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import {
    AuthoringBlockType,
    BlockStructureType,
    HorizontalAlignType,
    TextStyleType,
    VerticalAlignType,
    DEFAULT_BLOCK_ELEMENT_HEIGHT,
    ListStyleType,
    TextStyleEnum
} from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _, tinycolor } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import perf from "js/core/utilities/perf";
import { themeColors } from "js/react/sharedStyles";
import { ds } from "js/core/models/dataService";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import { htmlToText } from "js/core/utilities/htmlTextHelpers";
import SvgTextModel from "common/utils/SvgTextModel";

import { BaseElement } from "../BaseElement";
import { CollectionElement } from "../CollectionElement";
import { calcAuthoringBlockProps } from "../Text/TextLayoutHelpers";
import { AuthoringBlockContainer } from "../../elements/authoring/AuthoringBlockContainer";
import { FindBestFit } from "../../layouts/FindBestFit";
import {
    BulletDecoration,
    CheckListDecoration,
    IconListDecoration,
    NumberedListDecoration,
} from "../../elements/authoring/AuthoringBlocks/ListDecorations";

const logger = getLogger(LogGroup.ELEMENTS);

const TextBlockContainer = styled.div`
  position: relative;
`;

const TextIsClippedWarning = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  z-index: 10000;

  > div.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(-45deg, #D3E9F6, #D3E9F6 10px, #50bbe6 10px, #50bbe6 20px);
    opacity: 0.5;
  }

  > div.label {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 0);
    background: ${themeColors.ui_blue};
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 6px 10px;
  }
`;

export const USE_STYLESHEET_FOR_TEXT_STYLES = "_use_styles_";

export function MigrateHtml(model, forElement) {
    if (typeof model === "string") {
        return [model];
    }

    if (typeof model == "object" && model.hasOwnProperty("text")) {
        const textModel = new SvgTextModel(model);
        if (textModel.paragraphs.length === 0) {
            return [""];
        }

        const blockHtmls = [];

        const wrapInNode = (nodeToWrap, wrapperTag, wrapperAttributes = {}, wrapperStyles = {}) => {
            const wrapperNode = document.createElement(wrapperTag);
            Object.entries(wrapperAttributes).forEach(([name, value]) => wrapperNode.setAttribute(name, value));
            Object.entries(wrapperStyles).forEach(([name, value]) => wrapperNode.style.setProperty(name, value));
            wrapperNode.replaceChildren(nodeToWrap);
            return wrapperNode;
        };

        for (const paragraph of textModel.paragraphs) {
            const tempNode = document.createElement("div");
            tempNode.setAttribute("class", "migrate-html-container");
            document.body.appendChild(tempNode);

            let currentText = "";
            let currentStyle = null;

            const finalizeCurrentText = () => {
                if (!currentText) {
                    return;
                }

                let wordNode = document.createTextNode(currentText);

                if (currentStyle.color) {
                    const color = tinycolor(currentStyle.color).isValid()
                        ? currentStyle.color
                        : forElement.canvas.getTheme().palette.getColor(currentStyle.color).toRgbString();
                    wordNode = wrapInNode(wordNode, "font", {}, { color });
                }

                if (currentStyle.link) {
                    wordNode = wrapInNode(wordNode, "a", { href: currentStyle.link });
                }

                if (currentStyle.style === TextStyleEnum.BOLDITALIC) {
                    wordNode = wrapInNode(wordNode, "font", { class: "emphasized" });
                    wordNode = wrapInNode(wordNode, "i");
                } else if (currentStyle.style === TextStyleEnum.BOLD) {
                    wordNode = wrapInNode(wordNode, "font", { class: "emphasized" });
                } else if (currentStyle.style === TextStyleEnum.ITALIC) {
                    wordNode = wrapInNode(wordNode, "i");
                }

                tempNode.appendChild(wordNode);
            };

            for (const word of paragraph.words) {
                const { text, ...wordStyle } = word;

                if (!currentStyle) {
                    currentStyle = wordStyle;
                }

                let wordText = text;
                if (currentText.endsWith(" ") && wordText === " ") {
                    wordText = "\u00A0";
                } else if (wordText === "\r") {
                    wordText = "";
                }

                if (_.isEqual(wordStyle, currentStyle)) {
                    currentText += wordText;
                    continue;
                }

                finalizeCurrentText();

                currentText = wordText;
                currentStyle = wordStyle;
            }

            finalizeCurrentText();

            blockHtmls.push(sanitizeHtml(tempNode.innerHTML));
            tempNode.remove();
        }

        return blockHtmls;
    }

    return [""];
}

export function GetBlockModelsFromMigratedHtml(model, forElement, textStyle = null, splitParagraphs = false) {
    const getBlock = html => ({
        id: uuid(),
        html,
        type: AuthoringBlockType.TEXT,
        textStyle,
        hyphenation: false,
        ligatures: true,
        fontScale: model?.userFontScale ?? 1, // this block-specific userFontScale is used by contentItem in annotation layer
        indent: 0
    });

    if (splitParagraphs) {
        const paragraphs = MigrateHtml(model, forElement);

        // Trim trailing empty paragraphs
        while (paragraphs.length > 0 && _.isEmpty(paragraphs[paragraphs.length - 1])) {
            paragraphs.pop();
        }

        if (paragraphs.length === 0) {
            // Return placeholder block if all paragraphs are blank
            return [getBlock("")];
        } else {
            return paragraphs.map(getBlock);
        }
    } else {
        return [getBlock(MigrateHtml(model, forElement).join("<br>"))];
    }
}

export class TextElement extends BaseElement {
    get _canSelect() {
        return true;
    }

    get linkToSlideAsLink() {
        // Treating link to slide as a link unless explicitly set to false
        return this.options.linkToSlideAsLink ?? true;
    }

    get canSelect() {
        if (ds.selection.element?.isChildOf(this)) {
            return true;
        }

        return super.canSelect;
    }

    setupElement() {
        this.blockContainerRef = React.createRef();
    }

    get bindTo() {
        // return this.options.bindTo || "text";// this.id;
        return this.options.bindTo || this.id;
    }

    get allowDirectSelection() {
        return this.options.allowDirectSelection ?? true;
    }

    get defaultBlockTextStyle() {
        if (this.options.defaultBlockTextStyle) {
            return this.options.defaultBlockTextStyle;
        } else {
            switch (this.blockStructure) {
                case BlockStructureType.FREEFORM:
                case BlockStructureType.HEADER:
                    return TextStyleType.HEADING;
                case BlockStructureType.SINGLE_BLOCK:
                    return USE_STYLESHEET_FOR_TEXT_STYLES;
                default:
                    return TextStyleType.TITLE;
            }
        }
    }

    get defaultBlockListStyle() {
        if (this.options.defaultBlockListStyle) {
            return this.options.defaultBlockListStyle;
        }

        const textStyle = this.defaultBlockTextStyle;
        if (textStyle == TextStyleType.BULLET_LIST) {
            return ListStyleType.BULLET;
        } else if (textStyle == TextStyleType.NUMBERED_LIST) {
            return ListStyleType.NUMBERED;
        }

        return null;
    }

    get blockDefaults() {
        return this.options.blockDefaults;
    }

    get blockStructure() {
        return this.options.blockStructure ?? BlockStructureType.SINGLE_BLOCK;
    }

    get allowedBlockTypes() {
        if (this.options.allowedBlockTypes) {
            return this.options.allowedBlockTypes;
        } else {
            switch (this.blockStructure) {
                case BlockStructureType.FREEFORM:
                    return [
                        TextStyleType.HEADING,
                        TextStyleType.TITLE,
                        TextStyleType.BODY,
                        TextStyleType.BULLET_LIST,
                        TextStyleType.CAPTION,
                        TextStyleType.LABEL,
                        AuthoringBlockType.MEDIA,
                        AuthoringBlockType.DIVIDER,
                        AuthoringBlockType.CODE,
                        AuthoringBlockType.EQUATION
                    ];
                case BlockStructureType.TITLE_AND_BODY:
                    return [TextStyleType.TITLE, TextStyleType.BODY, TextStyleType.BULLET_LIST];
                case BlockStructureType.HEADER:
                    return [TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.LABEL, TextStyleType.BODY];
                case BlockStructureType.SINGLE_BLOCK:
                    return [];
            }
        }
    }

    get syncFontSizeWithSiblings() {
        if (this.options.syncFontSizeWithSiblings) {
            return this.textModel.syncFontSizeWithSiblings ?? true;
        } else {
            return false;
        }
    }

    get syncFontScaleAcrossSameBlockTypes() {
        if (this.blockStructure === BlockStructureType.SINGLE_BLOCK) {
            return false;
        }

        return this.options.syncFontScaleAcrossSameBlockTypes ?? true;
    }

    get allowAlignment() {
        return this.options.allowAlignment ?? false;
    }

    get allowListStyling() {
        if (this.allowedBlockTypes.contains(TextStyleType.BULLET_LIST)) {
            return this.options.allowListStyling ?? true;
        } else {
            return false;
        }
    }

    get showTextIsClippedWarning() {
        return this.options.showTextIsClippedWarning ?? true;
    }

    get isInteractive() {
        return this.textModel.blocks.some(block => block.linkToSlide);
    }

    get autoWidth() {
        return false;
    }

    get autoHeight() {
        return false;
    }

    get textBounds() {
        return this.calculatedProps?.textBounds;
    }

    get minHeight() {
        // Rough and ugly estimation of min height of the blocks
        let height = 0;
        this.textModel.blocks.forEach(block => {
            if (block.type === AuthoringBlockType.TEXT) {
                const textStyles = this.getTextStylesForBlock(block.id);
                if (textStyles) {
                    height += textStyles.fontSize + textStyles.spaceAbove + textStyles.spaceBelow;
                }
            } else {
                height += block.blockHeight ?? 0;
            }
        });
        return height;
    }

    get textModel() {
        // if the element was created with an html option, it doesn't use the model so we just generate a fake model
        const html = this.calculatedOptions?.html ?? this.options.html;
        if (html) {
            return {
                blocks: [{
                    id: "html",
                    html: html.toString(),
                    type: AuthoringBlockType.TEXT,
                    textStyle: USE_STYLESHEET_FOR_TEXT_STYLES
                }]
            };
        }

        const { dataSource } = this.model;
        if (dataSource) {
            const block = {
                id: "dataSource",
                html: null,
                type: AuthoringBlockType.TEXT,
                textStyle: this.defaultBlockTextStyle ?? TextStyleType.BODY,
                listStyle: this.defaultBlockListStyle
            };

            try {
                const sourceElement = this.canvas.getElementByUniquePath(dataSource.elementId);
                block.html = sourceElement.getDynamicValue(dataSource);
            } catch (err) {
                logger.error(err, `Couldn't fetch dynamic value from element ${dataSource.elementId}`, { elementId: dataSource.elementId, slideId: this.canvas.dataModel?.id });
            }

            if (block.html !== null) {
                // Display dynamic value only if it's not null
                return { blocks: [block] };
            }
        }

        // Text model containing blocks
        const model = this.model[this.bindTo];

        if (!model && this.options.defaultValue) {
            return {
                blocks: [{
                    id: "defaultValue",
                    html: this.options.defaultValue,
                    type: AuthoringBlockType.TEXT
                }]
            };
        }

        // this code is used to migrate the model when it bindTo property isn't a blocks array (ie. just a string or old text object)
        // it will only happen if the model doesn't have any blocks defined
        // arguably, it should happen in migration but there are templates that still rely on this behavior i think

        // this also creates a weird side effect: when you delete contentBlocks from a PhotoCollage, we set the model to null but because
        // there is a post-delete generate, this code will end up recreating an empty block for the deleted text. So we are checking for
        // the element being deleted (this.generationKey !== this.canvas.getCanvasElement().generationKey) to avoid this....
        if (!model?.blocks) {
            if (this.canvas.getCanvasElement() && this.generationKey !== this.canvas.getCanvasElement().generationKey) {
                // Render a faux block if the element has been deleted
                return {
                    blocks: [{
                        id: "faux",
                        html: "",
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.BODY
                    }]
                };
            }

            let blockTextStyle = this.defaultBlockTextStyle;
            if (!blockTextStyle) {
                switch (this.blockStructure) {
                    case BlockStructureType.FREEFORM:
                    case BlockStructureType.HEADER:
                        blockTextStyle = TextStyleType.HEADING;
                        break;
                    case BlockStructureType.SINGLE_BLOCK:
                    case BlockStructureType.TITLE_AND_BODY:
                    default:
                        blockTextStyle = TextStyleType.TITLE;
                        break;
                }
            }

            let html;
            if (typeof this.model[this.bindTo] == "object") {
                // support legacy model where value was an object with .text property
                html = this.model[this.bindTo]?.text?.toString() ?? "";
            } else {
                html = this.model[this.bindTo]?.toString() ?? "";
            }

            this.model[this.bindTo] = {
                blocks: [{
                    id: `migrated-${this.bindTo}`,
                    html,
                    type: AuthoringBlockType.TEXT,
                    textStyle: blockTextStyle,
                    listStyle: this.defaultBlockListStyle
                }]
            };
            return this.model[this.bindTo];
        }

        if (!Array.isArray(model.blocks)) {
            // Handling non-array block models, the underlying cause is fixed in BA-12378
            model.blocks = Object.values(model.blocks);
        }
        model.blocks = model.blocks.filter(block => block && (typeof block === "object"));

        // Ensuring minimal set of values in each block
        model.blocks.forEach(block => {
            Object.assign(block, {
                id: block.id ?? uuid(),
                html: block.html ?? "",
                type: block.type ?? AuthoringBlockType.TEXT,
                textStyle: block.textStyle ?? this.defaultBlockTextStyle,
                listStyle: block.listStyle ?? this.defaultBlockListStyle,
            });

            // List style is only allowed for lists
            if (block.listStyle && ![TextStyleType.BULLET_LIST, TextStyleType.NUMBERED_LIST].includes(block.textStyle)) {
                block.listStyle = null;
            }
        });

        if (model.blocks.length == 0) {
            model.blocks.push({
                id: uuid(),
                type: AuthoringBlockType.TEXT,
                html: "",
                textStyle: TextStyleType.TITLE
            });
        }

        return model;
    }

    get allowEmphasized() {
        return this.options.allowEmphasized ?? true;
    }

    get matchTextScaleId() {
        return this.options.matchTextScaleId ?? this.id;
    }

    get blocks() {
        return Object.values(this.blockContainerRef.current.blockRefs).map(ref => ref.current);
    }

    getNumberedBulletsStartSequenceNum(indent) {
        return this.options.getNumberedBulletsStartSequenceNum ? this.options.getNumberedBulletsStartSequenceNum(indent) : 1;
    }

    // add a new block to the model
    addBlock(options) {
        let block = {
            id: uuid(),
            type: options.type || AuthoringBlockType.TEXT,
            html: options.html || "",
            textStyle: options.textStyle,
            listStyle: options.listStyle,
            listDecorationStyle: options.listDecorationStyle,
            useThemedListDecoration: options.useThemedListDecoration
        };
        if (options.index !== undefined) {
            this.textModel.blocks.insert(block, options.index);
        } else {
            this.textModel.blocks.push(block);
        }
        return block;
    }

    // get a react ref to a block
    getBlockRef(blockId) {
        return this.blockContainerRef.current.blockRefs[blockId].current;
    }

    getBlockById(blockId) {
        return this.textModel.blocks.findById(blockId);
    }

    getBlockByTextStyle(textStyle) {
        return this.textModel.blocks.find(block => block.textStyle == textStyle);
    }

    hasBlock(textStyle) {
        return this.getBlockByTextStyle(textStyle) != null;
    }

    get firstBlock() {
        return this.textModel.blocks[0];
    }

    get lastBlock() {
        return _.last(this.textModel.blocks);
    }

    get blockSpacing() {
        return this.styles.blockSpacing ?? 0;
    }

    get textAlign() {
        const html = this.calculatedOptions?.html ?? this.options.html;

        let textAlign;
        if (html) {
            textAlign = this.calculatedOptions?.textAlign ?? this.options.textAlign ?? this.styles.textAlign ?? HorizontalAlignType.LEFT;
        } else {
            if (this.allowAlignment) {
                textAlign = this.model.textAlign ?? this.calculatedOptions?.textAlign ?? this.options.textAlign ?? this.styles.textAlign ?? HorizontalAlignType.LEFT;
            } else {
                // ignore model when allowAlignment = false - it may be left over from switching variations
                textAlign = this.calculatedOptions?.textAlign ?? this.options.textAlign ?? this.styles.textAlign ?? HorizontalAlignType.LEFT;
            }
        }

        if (this.isRTL && (textAlign == HorizontalAlignType.LEFT || !textAlign)) {
            textAlign = HorizontalAlignType.RIGHT;
        }
        return textAlign;
    }

    get verticalAlign() {
        const html = this.calculatedOptions?.html ?? this.options.html;
        if (html) {
            return this.calculatedOptions?.verticalAlign ?? this.options.verticalAlign ?? this.styles.verticalAlign ?? VerticalAlignType.TOP;
        }

        return this.model.verticalAlign ?? this.calculatedOptions?.verticalAlign ?? this.options.verticalAlign ?? this.styles.verticalAlign ?? VerticalAlignType.TOP;
    }

    getPlaceholderForBlock(block) {
        if (this.options.getPlaceholderForBlock) {
            return this.options.getPlaceholderForBlock(block);
        }

        switch (block.textStyle) {
            case TextStyleType.BULLET_LIST:
                if (block.indent > 0) {
                    return { text: "Type sub bullet" };
                } else {
                    return { text: "Type bullet" };
                }
            default:
                if (this.options.hasOwnProperty(block.textStyle) && this.options[block.textStyle].hasOwnProperty("placeholder")) {
                    return { text: this.options[block.textStyle].placeholder };
                }

                if (this.blockStructure === BlockStructureType.SINGLE_BLOCK) {
                    if (this.options.placeholder) {
                        return { text: this.options.placeholder };
                    } else {
                        return { text: "Type text" };
                    }
                } else {
                    return { text: `Type ${this.getTextStyleForBlock(block)}` };
                }
        }
    }

    getTextStyleForBlock(block) {
        if (this.styles.textStyle) {
            return this.styles.textStyle;
        }

        if (this.blockStructure == BlockStructureType.SINGLE_BLOCK && this.allowedBlockTypes.length == 0) {
            return USE_STYLESHEET_FOR_TEXT_STYLES;
        } else {
            return block.textStyle || block.id;
        }
    }

    get usedFontIds() {
        const fontIds = new Set();
        const processStyles = styles => {
            Object.entries(styles)
                .forEach(([key, value]) => {
                    if (key === "fontId" && typeof value === "string") {
                        fontIds.add(value);
                    } else if (value && typeof value === "object") {
                        processStyles(value);
                    }
                });
        };
        processStyles(this.styles);

        if (this.textModel?.blocks) {
            this.textModel.blocks.forEach(block => {
                if (block.fontFamily) {
                    fontIds.add(block.fontFamily);
                }

                if (block.html) {
                    const domparser = new DOMParser();
                    const document = domparser.parseFromString(block.html, "text/html");
                    document.querySelectorAll("*").forEach(element => {
                        if (element.style) {
                            const fontFamily = element.style.getPropertyValue("font-family");
                            if (fontFamily) {
                                fontIds.add(fontFamily);
                            }
                        }
                    });
                }
            });
        }

        return [...fontIds];
    }

    onAdjacentBlocksListStyleChanged(indent, listStyle, options) {
        if (this.options.onAdjacentBlocksListStyleChanged) {
            this.options.onAdjacentBlocksListStyleChanged(indent, listStyle, options);
        }
    }

    getTextStylesForStyleType(block) {
        const textStyleType = this.getTextStyleForBlock(block);

        // load the base styles for this textStyle
        let textStyles = {};
        _.merge(textStyles, this.styles.TextStyles[textStyleType] ?? {});

        if (this.blockStructure == BlockStructureType.SINGLE_BLOCK && this.allowedBlockTypes.length == 0) {
            // handles simple text cases where the element id is used for styles
            textStyles = _.merge(textStyles, this.styles);
        } else {
            _.merge(textStyles, this.styles[textStyleType] ?? {});
        }

        if (textStyleType == TextStyleType.BULLET_LIST) {
            const indentStyles = textStyles[`indent_${Math.min(1, block.indent ?? 0)}`];
            if (indentStyles) {
                _.merge(textStyles, indentStyles, indentStyles[block.listStyle] || {});
            }
        }

        return textStyles;
    }

    getTextStylesForBlock(blockId) {
        const block = this.textModel.blocks.findById(blockId) ?? this.textModel.blocks[0];

        if (block.type !== AuthoringBlockType.TEXT) {
            return {};
        }
        // get the styles for the text by either the model's textStyle
        // or if no textStyle exists, use the id of the block
        // and if no specific styles are defined for the text style, just use the root styles
        // const textStyleType = this.getTextStyleForBlock(block);
        let textStyles = this.getTextStylesForStyleType(block);

        const getBlockTextStyles = textStyles => {
            let hasEmphasized = false;
            if (block.html) {
                const domparser = new DOMParser();
                const document = domparser.parseFromString(block.html, "text/html");
                document.querySelectorAll("*").forEach(element => {
                    if (element.getAttribute && element.getAttribute("class") === "emphasized") {
                        hasEmphasized = true;
                    }
                });
            }

            const styles = {
                fontFamily: block.fontFamily ?? textStyles.fontId,
                baseFontSize: Math.round(textStyles.fontSize),
                fontColor: textStyles.fontColor,
                bulletColor: textStyles.bulletColor,
                bulletScale: textStyles.bulletScale ?? 1,
                bulletSpacing: textStyles.bulletSpacing ?? 2.5,
                bulletIndent: textStyles.bulletIndent ?? 50,
                fontScaling: textStyles.fontScaling,
                fontWeight: block.fontWeight ?? textStyles.fontWeight,
                letterSpacing: (textStyles.letterSpacing ?? 0),
                lineHeight: textStyles.lineHeight,
                textTransform: textStyles.textTransform,
                spaceBelow: textStyles.spaceBelow ?? 0,
                spaceAbove: textStyles.spaceAbove ?? 0,
                blockSpacing: textStyles.blockSpacing ?? this.blockSpacing,
                paragraphSpacing: textStyles.paragraphSpacing,
                boldStyles: textStyles.bold,
                numberStyles: textStyles.number,
                blockDecorationStyles: textStyles.blockDecoration,
                maxLines: this.maxLines ?? textStyles.maxLines ?? 9999, // Number.POSITIVE_INFINITY isn't serializable so we have to use just a large number
                evenBreak: false,
                listDecorationStyle: block.listDecorationStyle ?? "theme",
                allowFancyNumberedDecorations: textStyles.allowFancyNumberedDecorations ?? false,
                hasEmphasized,
                blockInset: textStyles.blockInset ?? 0,
                isRTL: this.isRTL
            };

            if (this.blockDefaults?.[block.textStyle]) {
                _.merge(styles, this.blockDefaults[block.textStyle]);
            }

            if (!this.options.disableEvenBreak && block.evenBreak != null) {
                styles.evenBreak = block.evenBreak;
            }

            // adjust fontSize by fontScaling styles properties (from theme font settings)
            styles.baseFontSize *= ((styles.fontScaling ?? 100) / 100);
            styles.baseFontSize *= this.canvas.getTheme().get("fontScale") ?? 1;

            // use avaialble model overrides for these style properties
            if (this.syncFontScaleAcrossSameBlockTypes) {
                if (block.textStyle == TextStyleType.BULLET_LIST) {
                    if (block.indent == 0) {
                        styles.fontScale = this.textModel.blockFontScales?.[TextStyleType.BULLET_LIST]?.[0] ?? 1;
                    } else {
                        styles.fontScale = this.textModel.blockFontScales?.[TextStyleType.BULLET_LIST]?.[1] ?? 1;
                    }
                } else {
                    styles.fontScale = this.textModel.blockFontScales?.[block.textStyle] ?? 1;
                }
            } else {
                styles.fontScale = this.textModel.blockFontScales?.[block.textStyle] ?? 1;
            }

            styles.fontSize = (block.fontSize ?? styles.baseFontSize) * styles.fontScale;

            if (this.options.forceTextSize) {
                styles.fontSize = this.options.forceTextSize;
            }

            styles.lineHeight = block.lineHeight ?? styles.lineHeight;
            styles.letterSpacing = block.letterSpacing ?? styles.letterSpacing;
            styles.letterSpacing += "em";

            styles.textAlign = this.textAlign;

            if (block.textStyle === TextStyleType.BULLET_LIST) {
                styles.evenBreak = false;
                styles.textAlign = this.isRTL ? HorizontalAlignType.RIGHT : HorizontalAlignType.LEFT;
            }

            if (styles.textTransform == "auto") {
                styles.textTransform = "none"; // convert to valid css value
            }

            return styles;
        };

        return getBlockTextStyles(textStyles);
    }

    getSiblings() {
        let siblings = this.options.getSiblings && this.options.getSiblings();
        if (!siblings) {
            const collectionElement = this.findClosestOfType(CollectionElement);
            if (collectionElement) {
                const itemElements = collectionElement.itemElements.filter(element => !!element);
                siblings = itemElements.filter(element => element?.isInstanceOf("TextElement"));
                if (siblings.length === 0) {
                    siblings = [];
                    itemElements.forEach(element => {
                        const textElements = element.allChildElements.filter(element => element?.isInstanceOf("TextElement") && element.syncFontSizeWithSiblings && element.id == this.id);
                        siblings.push(...textElements);
                    });
                }
            }
        }

        if (!siblings || !siblings.length) {
            return [this];
        }

        return siblings.filter(element => !!element);
    }

    updateText(html) {
        // note: this could be made more resilient to handle multiple blocks but i dont think there are any cases for this right now
        if (this.model[this.bindTo].blocks.length) {
            this.model[this.bindTo].blocks[0].html = html;
        }
    }

    _build() {
        this.blockElements = {};
        this.listDecorations = {};
        let bulletIndex = 0;

        for (const blockModel of this.textModel.blocks) {
            if (blockModel.id == null) {
                blockModel.id = uuid();
            }

            if (blockModel.indent == null) {
                blockModel.indent = 0;
            }

            if (blockModel.type == AuthoringBlockType.DIVIDER && blockModel.blockHeight == null) {
                blockModel.blockHeight = 20;
            }

            if (blockModel.type == AuthoringBlockType.ELEMENT || blockModel.type == AuthoringBlockType.MEDIA || blockModel.type == AuthoringBlockType.CARDS) {
                if (!blockModel.elementModel) {
                    let elementType;
                    switch (blockModel.type) {
                        case AuthoringBlockType.CARDS:
                            elementType = "Cards";
                            break;
                        case AuthoringBlockType.MEDIA:
                        default:
                            elementType = "MediaBlock";
                            break;
                    }

                    blockModel.elementModel = {
                        elementType
                    };
                }

                // Ensure default block model values
                if (blockModel.type === AuthoringBlockType.MEDIA) {
                    blockModel.autoWidth = blockModel.autoWidth ?? true;
                    blockModel.blockHeight = blockModel.blockHeight ?? 100;
                } else if (blockModel.type == AuthoringBlockType.CARDS) {
                    blockModel.autoHeight = blockModel.autoHeight ?? true;
                } else {
                    blockModel.blockHeight = blockModel.blockHeight ?? DEFAULT_BLOCK_ELEMENT_HEIGHT;
                }

                const elementClass = this.canvas.elementManager.get(blockModel.elementModel.elementType);
                const element = this.addElement(blockModel.id, () => elementClass, {
                    model: blockModel.elementModel,
                    blockId: blockModel.id,
                    canSelect: false
                });
                this.blockElements[blockModel.id] = element;
            }

            if (blockModel.type == AuthoringBlockType.TEXT && blockModel.textStyle == TextStyleType.BULLET_LIST && blockModel.listStyle !== ListStyleType.TEXT) {
                let listDecoration;
                switch (blockModel.listStyle) {
                    case ListStyleType.BULLET:
                        listDecoration = this.addElement("listDecoration" + blockModel.id, () => BulletDecoration, {
                            model: {
                                color: blockModel.bulletColor,
                                bulletIndex,
                                indent: blockModel.indent
                            }
                        });

                        break;
                    case ListStyleType.NUMBERED:
                        listDecoration = this.addElement("listDecoration" + blockModel.id, () => NumberedListDecoration, {
                            model: {
                                color: blockModel.bulletColor,
                                bulletIndex,
                                index: this.getNumberedBulletsStartSequenceNum(blockModel.indent),
                                indent: blockModel.indent,
                            },
                            listDecorationStyle: blockModel.listDecorationStyle
                        });
                        break;
                    case ListStyleType.CHECKBOX:
                        listDecoration = this.addElement("listDecoration" + blockModel.id, () => CheckListDecoration, {
                            model: {
                                color: blockModel.bulletColor,
                                bulletIndex,
                                checkState: blockModel.checkState,
                                indent: blockModel.indent
                            },
                        });
                        break;
                    case ListStyleType.ICON:
                        listDecoration = this.addElement("listDecoration" + blockModel.id, () => IconListDecoration, {
                            model: {
                                color: blockModel.bulletColor,
                                bulletIndex,
                                iconId: blockModel.listIconId,
                                indent: blockModel.indent
                            },
                        });
                        break;
                }

                if (listDecoration) {
                    listDecoration.blockModel = blockModel;
                    this.listDecorations[blockModel.id] = listDecoration;
                }
                if (blockModel.indent == 0) {
                    bulletIndex++;
                }
            }
        }

        if (this.syncFontSizeWithSiblings) {
            this.parentTextScaleManagingElement = this.getParentElementForMatchTextScale();

            if (!this.textModel.blockFontScales) {
                const siblings = this.getSiblings();
                if (siblings && siblings.length > 0) {
                    // if this text doesn't have blockFontScales, copy from the first sibling
                    this.textModel.blockFontScales = _.cloneDeep(siblings[0].textModel.blockFontScales);
                }
            }
        }
    }

    getParentElementForMatchTextScale() {
        if (this.options.getParentElementForMatchTextScale) {
            return this.options.getParentElementForMatchTextScale();
        } else {
            return this.findClosestOfType(CollectionElement);
        }
    }

    _calcProps(props, options) {
        perf.start("TextElement:_calcProps");

        const { size } = props;

        const autoWidth = options.autoWidth ?? this.autoWidth;
        const autoHeight = options.autoHeight ?? this.autoHeight;

        const columns = options.columns || 1;
        const columnGap = this.styles.columnGap ?? 20;

        let textScale = options.forceTextScale;
        if (this.canvas.layouter.isPostCalcProps && this.syncFontSizeWithSiblings && this.parentTextScaleManagingElement && options.scaleTextToFit) {
            // if we are syncing our fontsize with our siblings and this is a recalcProps called from the CollectionElement postCalcProps, force the textScale to match
            // note: we only need to do this when scaleTextToFit = true
            textScale = this.parentTextScaleManagingElement.matchedTextElements?.[this.matchTextScaleId]?.textScale || textScale;
        }

        let blockProps = [];
        let containerSize;
        let blocksOverflowSize;
        let isTextFit = true;

        Object.values(this.blockElements)
            .forEach(blockElement => {
                const blockModel = this.textModel.blocks.findById(blockElement.id);
                const blockHeight = blockModel.autoHeight ? size.height : blockModel.blockHeight;
                blockElement.calcProps(new geom.Size(size.width, blockHeight), {
                    isBlockElement: true
                });
            });

        if (options.scaleTextToFit && !textScale) {
            const minScale = options.minTextScale ?? 0.5;

            const { isFit, fitValue, scaledBlockProps, scaledContainerSize, scaledBlocksOverflowSize } = FindBestFit({
                min: minScale,
                max: 1,
                preCheckMax: true,
                layout: scale => {
                    const { containerSize, blocksOverflowSize, blockProps } = calcAuthoringBlockProps({
                        element: this,
                        model: this.textModel,
                        width: size.width,
                        autoWidth,
                        scale,
                        canvas: this.canvas,
                        blockSpacing: this.blockSpacing,
                        height: (columns > 1) ? size.height : null,
                        columns,
                        columnGap,
                        textAlign: this.textAlign
                    });

                    const isFit =
                        blocksOverflowSize.height <= size.height &&
                        blocksOverflowSize.width <= size.width &&
                        blockProps.filter(block => block.type == AuthoringBlockType.TEXT).every(block => block.lines.length <= block.textStyles.maxLines);

                    return {
                        isFit,
                        scaledContainerSize: containerSize,
                        scaledBlocksOverflowSize: blocksOverflowSize,
                        scaledBlockProps: blockProps
                    };
                }
            });

            isTextFit = isFit;
            blocksOverflowSize = scaledBlocksOverflowSize;
            containerSize = scaledContainerSize;
            blockProps = scaledBlockProps;
            textScale = fitValue ?? minScale;
        } else {
            // calculate the block props
            ({ containerSize, blocksOverflowSize, blockProps } = calcAuthoringBlockProps({
                element: this,
                model: this.textModel,
                width: size.width,
                autoWidth,
                scale: textScale || 1,
                canvas: this.canvas,
                blockSpacing: this.blockSpacing,
                height: (columns > 1) ? size.height : null,
                columns,
                columnGap,
                textAlign: this.textAlign
            }));

            isTextFit =
                blocksOverflowSize.height <= size.height &&
                blocksOverflowSize.width <= size.width &&
                blockProps.filter(block => block.type == AuthoringBlockType.TEXT).every(block => block.lines.length <= block.textStyles.maxLines);
        }

        for (const block of blockProps) {
            block.allowListStyling = true;
        }

        const textBounds = new geom.Rect(this.styles.paddingLeft ?? 0, this.styles.paddingTop ?? 0, containerSize);

        const calculatedSize = size.clone();
        if (autoWidth) {
            // when autowidth, set the calculatedSize to width of the text
            calculatedSize.width = containerSize.width;
        }
        if (autoHeight) {
            // when autoheight, set the calculatedSize to the height of the text constrained by the available size
            calculatedSize.height = Math.min(size.height, containerSize.height);

            // special case for empty single block, if the actual text height is bigger than container height (i.e. not fit)
            // then position text in the middle
            if (blockProps.length === 1 && !blockProps[0].model.html && blocksOverflowSize.height > calculatedSize.height) {
                textBounds.top += calculatedSize.height / 2 - blocksOverflowSize.height / 2;
            }
        }

        if (!autoHeight) {
            switch (this.verticalAlign) {
                case VerticalAlignType.MIDDLE:
                    textBounds.top += size.height / 2 - containerSize.height / 2;
                    break;
                case VerticalAlignType.BOTTOM:
                    textBounds.top += calculatedSize.height - containerSize.height;
                    break;
            }
        }

        if (textBounds.bottom > size.height) {
            textBounds.height -= textBounds.bottom - size.height;
        }

        Object.values(this.blockElements)
            .forEach(blockElement => {
                // Assign correct calculated bounds to block element
                blockElement.calculatedProps.bounds = blockProps.findById(blockElement.id).bounds.offset(textBounds.position);
            });

        // calc any list decorations
        Object.keys(this.listDecorations)
            .forEach(blockId => {
                let block = blockProps.findById(blockId);

                // let listDecorationSize = block.fontHeight * 2;
                let listDecorationSize = this.listDecorations[blockId].getSize(block.fontHeight);

                let listDecProps = this.listDecorations[blockId].calcProps(new geom.Size(listDecorationSize, listDecorationSize), {
                    textStyles: block.textStyles,
                    sequenceNum: block.sequenceNum
                });

                if (this.textAlign == HorizontalAlignType.RIGHT) {
                    listDecProps.bounds = new geom.Rect(size.width - block.indentWidth - listDecProps.size.width, block.topSpace + block.fontHeight / 2 - listDecorationSize / 2, listDecProps.size);
                } else {
                    listDecProps.bounds = new geom.Rect(block.indentWidth, block.topSpace + block.fontHeight / 2 - listDecorationSize / 2, listDecProps.size);
                }
            });

        perf.stop("TextElement:_calcProps");

        if (!this.canvas.layouter.isPostCalcProps && this.syncFontSizeWithSiblings && this.parentTextScaleManagingElement && options.scaleTextToFit) {
            // if we are in an inital calcProps (not a recalc), register our calculated textScale with our CollectionElement
            // note: we only need to do this when scaleTextToFit = true
            this.parentTextScaleManagingElement.registerTextElementForMatchScale(this, textScale);
        }

        return {
            size: calculatedSize,
            isTextFit,
            textScale,
            textBounds,
            blockProps,
            textAlign: this.textAlign,
            verticalAlign: this.verticalAlign,
            columns,
            columnGap
        };
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition, suppressRefreshSelectionLayer = false) {
        this.canvas.refreshElement(this.options.refreshElement ?? this, transition, suppressRefreshSelectionLayer);
    }

    renderChildren(transition) {
        const props = this.calculatedProps;

        let slideColor = this.getSlideColor();
        const backgroundColor = this.getBackgroundColor(this);

        // apply resolved color styles to our blockProps.textStyles
        for (const block of props.blockProps) {
            block.model.blockBounds = block.containerBounds?.toObject();
            block.model.textStyles = block.textStyles;
            block.model.blockMargin = block.blockMargin;
            block.model.topSpace = block.topSpace;
            block.model.bottomSpace = block.bottomSpace;

            if (block.type === AuthoringBlockType.TEXT) {
                const textStyles = this.getTextStylesForStyleType(block);

                if (textStyles.forceBackgroundColor) {
                    // in some cases, we need a specific block to force a specific backgroundColor (like block header)
                    let forcedBackgroundColor = this.canvas.getTheme().palette.getForeColor(textStyles.forceBackgroundColor, slideColor, backgroundColor);
                    block.textStyles.autoColor = this.canvas.getTheme().palette.getForeColor(textStyles.fontColor, slideColor, forcedBackgroundColor).toRgbString();
                } else {
                    block.textStyles.autoColor = textStyles.resolved_fontColor?.toRgbString();
                }

                if (block.model.fontColor) {
                    // if the block overriddes its color, use the block model's font color
                    block.textStyles.color = this.canvas.getTheme().palette.getColor(block.model.fontColor).toRgbString();
                } else {
                    block.textStyles.color = block.textStyles.autoColor;
                }

                let colorfulColor;
                if (this.canvas.getSlideColor() === "colorful" && !backgroundColor.isColor) {
                    let colorfulIndex;
                    if (props.blockProps.some(block => block.textStyle === TextStyleType.BULLET_LIST && block.indent === 0)) {
                        colorfulIndex = props.blockProps.filter(block => block.textStyle == TextStyleType.BULLET_LIST && block.indent == 0).indexOf(block) + this.getNumberedBulletsStartSequenceNum() - 1;
                    } else {
                        colorfulIndex = block.index + (this.itemIndex ?? 0);
                    }
                    colorfulColor = this.canvas.getTheme().palette.getColorfulColor(colorfulIndex).toRgbString();
                }

                if (textStyles.bold?.fontColor === "slide" && colorfulColor) {
                    // get the emphasized color from colorful
                    block.textStyles.emphasizedColor = colorfulColor;
                } else if (textStyles.bold?.fontColor) {
                    // get the emphasized color from the bold styles
                    block.textStyles.emphasizedColor = textStyles.bold.resolved_fontColor?.toRgbString();
                } else {
                    // fallback to just matching the text styles font color
                    block.textStyles.emphasizedColor = block.textStyles.color;
                }

                // Passing resolved theme colors down to block text styles so they get incorporated into the css
                block.textStyles.themeColors = Object.entries(this.calculatedStyles.TextStyles).reduce((themeColors, [styleKey, styleValue]) => {
                    if (styleKey.startsWith("resolved_") && styleKey.endsWith("_fontColor")) {
                        return { ...themeColors, [styleKey.replace("resolved_", "").replace("_fontColor", "")]: styleValue.toRgbString() };
                    }
                    return themeColors;
                }, {});

                if (this.overlay?.$textEditorEl?.length) {
                    let canvasBlockBounds = block.textBounds.offset(this.calculatedProps.textBounds.position).offset(this.calculatedProps.bounds.position);
                    if (this.parentElement) {
                        const parentCanvasBounds = this.parentElement.canvasBounds.deflate(this.parentElement.styles.padding ?? 0);
                        canvasBlockBounds = canvasBlockBounds.offset(parentCanvasBounds.left, parentCanvasBounds.top);
                    }

                    const canvasBounds = new geom.Rect(0, 0, this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT);
                    const hasOverflow = !canvasBounds.overlaps(canvasBlockBounds);
                    if (hasOverflow) {
                        const intersectionBounds = canvasBlockBounds.intersection(canvasBounds);
                        block.renderBounds = block.containerBounds.deflate({
                            left: intersectionBounds.left - canvasBlockBounds.left,
                            top: intersectionBounds.top - canvasBlockBounds.top,
                            right: canvasBlockBounds.right - intersectionBounds.right,
                            bottom: canvasBlockBounds.bottom - intersectionBounds.bottom
                        });
                        block.renderBounds.width += Math.max(0, block.textBounds.width - block.containerBounds.width);
                        block.renderBounds.width = Math.max(0, block.renderBounds.width);
                        block.renderBounds.height = Math.max(0, block.renderBounds.height);
                        block.hasCanvasOverflow = true;
                    } else {
                        block.hasCanvasOverflow = false;
                        block.renderBounds = null;
                    }
                } else {
                    block.hasCanvasOverflow = false;
                    block.renderBounds = null;
                }
            }
        }

        const containerStyles = {
            left: props.textBounds.left,
            top: props.textBounds.top,
            width: props.textBounds.width,
            height: props.textBounds.height,
        };

        if (!props.isTextFit) {
            containerStyles.overflow = "hidden";
        }

        const showTextIsClippedWarning = this.canvas.isEditable && !this.canvas.isPlayback && !props.isTextFit && this.showTextIsClippedWarning;

        const children = [
            <TextBlockContainer key={this.id} style={containerStyles} className="html-text-element">
                <AuthoringBlockContainer
                    element={this}
                    ref={this.blockContainerRef}
                    model={this.textModel}
                    isTextFit={props.isTextFit}
                    blockStructure={this.blockStructure}
                    blockProps={props.blockProps}
                    textAlign={this.textAlign}
                    blockSpacing={this.blockSpacing}
                    scale={props.textScale}
                    canvas={this.canvas}
                    columns={props.columns}
                    columnGap={props.columnGap}
                    slideColor={slideColor}
                    backgroundColor={backgroundColor}
                    allowDirectSelection={this.allowDirectSelection}
                    showCanvasOverflow={!showTextIsClippedWarning}
                    isAnimating={this.isAnimating}
                    animationState={this.animationState}
                    ignoreSpellcheck={this.canvas.ignoreSpellcheck}
                />
            </TextBlockContainer>
        ];

        if (showTextIsClippedWarning) {
            children.push(
                <TextIsClippedWarning key="text-is-clipped-warning">
                    <div className="backdrop" />
                    <div className="label">Too much text</div>
                </TextIsClippedWarning>
            );
        }

        return children;
    }

    // region migrations
    _migrate_10() {
        if (this.model.blocks && !this.model[this.bindTo]) {
            // handle migration of legacy node elements
            this.model[this.bindTo] = {
                blocks: this.model.blocks
            };
            delete this.model.blocks;
        }

        if (this.model[this.bindTo]?.blocks) {
            // model already has blocks so do any block model migrations
            for (let block of this.model[this.bindTo].blocks) {
                // migrate legacy blocks
                if (block.content) {
                    block.textStyle = block.type;
                    block.type = AuthoringBlockType.TEXT;
                    block.html = MigrateHtml(block.content, this).join("<br>");
                    delete block.content;
                }

                if (block.type === "title" || block.type === "legacytitle" || block.textStyle === "legacytitle") {
                    block.type = AuthoringBlockType.TEXT;
                    block.textStyle = TextStyleType.TITLE;
                }
                if (block.type === "icon") {
                    block.type = AuthoringBlockType.MEDIA;
                }
                if (block.listStyleOptions) {
                    block.listIconId = block.listStyleOptions.icon;
                    delete block.listStyleOptions;
                }
            }
        } else if (this.model[this.bindTo]?.text) {
            // this is a model without blocks but only a single text property (like ArrowBar or ElementTextBox)
            let oldBindTo = this.options.bindTo || this.id;
            if (typeof this.model[oldBindTo] == "object" && this.model[oldBindTo].text) {
                this.model[this.bindTo].blocks = GetBlockModelsFromMigratedHtml(this.model[oldBindTo], this, this.defaultBlockTextStyle);
            }
            if (oldBindTo !== this.bindTo) {
                delete this.model[oldBindTo];
            }
        } else {
            // model has no blocks so migrate old text properties into a blocks collection
            switch (this.blockStructure) {
                case BlockStructureType.SINGLE_BLOCK:
                    let oldBindTo = this.options.bindTo || this.id;
                    if (typeof this.model[oldBindTo] == "object" && this.model[oldBindTo].text) {
                        this.model[this.bindTo].blocks.push(...GetBlockModelsFromMigratedHtml(this.model[oldBindTo], this, this.defaultBlockTextStyle));
                    }
                    if (oldBindTo !== this.bindTo) {
                        delete this.model[oldBindTo];
                    }
                    break;
                case BlockStructureType.TITLE_AND_BODY:
                    this.model[this.bindTo] = { blocks: [] };

                    if (this.model.title || !this.model.body) {
                        this.model[this.bindTo].blocks.push(...GetBlockModelsFromMigratedHtml(this.model.title, this, TextStyleType.TITLE));
                        delete this.model.title;
                    }

                    if (this.model.body && !_.isEmpty(this.model.body.text)) {
                        let splitPara = this.model.text_format != null;
                        let bodyBlocks = GetBlockModelsFromMigratedHtml(this.model.body, this, TextStyleType.BODY, splitPara);

                        for (let bodyBlock of bodyBlocks) {
                            switch (this.model.text_format) {
                                case "bullet_list":
                                    bodyBlock.textStyle = TextStyleType.BULLET_LIST;
                                    bodyBlock.listStyle = ListStyleType.BULLET;
                                    break;
                                case "numbered_list":
                                    bodyBlock.textStyle = TextStyleType.BULLET_LIST;
                                    bodyBlock.listStyle = ListStyleType.NUMBERED;
                                    break;
                            }
                        }
                        this.model[this.bindTo].blocks.push(...bodyBlocks);

                        delete this.model.body;
                    }
                    break;
                case BlockStructureType.HEADER:
                case BlockStructureType.FREEFORM:
                    this.model[this.bindTo] = { blocks: [] };
                    if (this.model.label && this.model.label.text != "") {
                        this.model[this.bindTo].blocks.push(...GetBlockModelsFromMigratedHtml(this.model.label, this, TextStyleType.LABEL));
                        delete this.model.label;
                    }

                    let titleBlocks = GetBlockModelsFromMigratedHtml(this.model.title, this, this.model.titleTextStyle ?? this.defaultBlockTextStyle);

                    // migrate different heading and caption styles for old content items
                    if (this.model.titleTextStyle == "heading") {
                        this.model[this.bindTo].blockFontScales = {
                            heading: 1.7
                        };
                    } else if (this.model.titleTextStyle == "caption") {
                        this.model[this.bindTo].blockFontScales = {
                            caption: 1.6
                        };
                    }
                    this.model[this.bindTo].blocks.push(...titleBlocks);

                    delete this.model.title;
                    if (this.model.description && this.model.description.text != "") {
                        this.model[this.bindTo].blocks.push(...GetBlockModelsFromMigratedHtml(this.model.description, this, TextStyleType.BODY));
                        delete this.model.description;
                    }
                    if (this.model.body && this.model.body.text != "") {
                        this.model[this.bindTo].blocks.push(...GetBlockModelsFromMigratedHtml(this.model.body, this, TextStyleType.BODY));
                        delete this.model.body;
                    }
                    break;
            }
        }

        // convert userFontScale to block fontSize
        const userFontScale = this.getRootElement().model.userFontScale;

        if (userFontScale) {
            // delete legacy userScale paths that may be in the model but are invalid
            delete userFontScale["CanvasElement/ProductScreenshot/ProductScreenshotList/TextListItem/TextGroup/TextGroupTitle"];
            delete userFontScale["CanvasElement/ProductScreenshot/ProductScreenshotList/TextListItem/TextGroup/TextGroupBody"];
            delete userFontScale["CanvasElement/VennDiagram/VennDiagramItem/TextElement"];

            const styles = this.loadStyles();
            const updateFontSize = (block, scale) => {
                if (block && scale) {
                    // let textStyles = styles[textStyleType] || styles;
                    let textStyles;
                    const textStyleType = this.getTextStyleForBlock(block);
                    if (this.id === textStyleType) {
                        // handles simple text cases where the element id is used for styles
                        textStyles = styles;
                    } else {
                        // load the base styles for this textStyle
                        textStyles = styles.TextStyles[textStyleType];
                        if (textStyles) {
                            // merge any style overrides for this specific instance
                            Object.assign(textStyles, styles[textStyleType]);
                        } else {
                            // try to fall back to the root styles
                            textStyles = styles;
                        }
                    }

                    const defaultFontSize = textStyles.fontSize * ((textStyles.fontScaling ?? 100) / 100);
                    block.fontSize = defaultFontSize * scale;
                }
            };
            const blocks = this.model[this.bindTo]?.blocks;
            if (blocks) {
                const blockFontScales = {};

                const elementPath = this.getElementTreePath().replace(this.type, "");
                const processUserFontScalePath = (userFontScalePath, scale) => {
                    if (scale !== 1 && userFontScalePath.contains(elementPath)) {
                        const scaleTarget = _.last(userFontScalePath.split("/"));
                        if (scaleTarget == "TextElement" || scaleTarget === this.type) {
                            // handles TextBoxGrid scaling
                            blockFontScales[USE_STYLESHEET_FOR_TEXT_STYLES] = scale;
                            blockFontScales[TextStyleType.TITLE] = scale;
                            blockFontScales[TextStyleType.BODY] = scale;
                            blockFontScales[TextStyleType.BULLET_LIST] = [scale, scale];
                        } else {
                            let targetTextStyle;
                            switch (scaleTarget) {
                                case "ContentBlockTextBigText":
                                    blockFontScales[TextStyleType.BIG_TEXT] = scale;
                                    break;
                                case "ContentBlockTextHeadline":
                                    blockFontScales[TextStyleType.HEADLINE] = scale;
                                    break;
                                case "ContentBlockTextHeading":
                                case "DisplayTextHeadline":
                                    blockFontScales[TextStyleType.HEADING] = scale;
                                    break;
                                case "ContentBlockTextTitle":
                                case "TextGroupTitle":
                                    blockFontScales[TextStyleType.TITLE] = scale;
                                    break;
                                case "TextGroupBody":
                                case "DisplayTextDescription":
                                case "ContentBlockTextBody":
                                    blockFontScales[TextStyleType.BODY] = scale;
                                    blockFontScales[TextStyleType.BULLET_LIST] = [scale, scale];
                                    break;
                                case "ContentBlockTextCaption":
                                    blockFontScales[TextStyleType.CAPTION] = scale;
                                    break;
                                case "DisplayTextLabel":
                                    blockFontScales[TextStyleType.LABEL] = scale;
                                    break;
                                case "BulletIndent0":
                                    blockFontScales[TextStyleType.BULLET_LIST] = blockFontScales[TextStyleType.BULLET_LIST] || [1, 1];
                                    blockFontScales[TextStyleType.BULLET_LIST][0] = scale;
                                    break;
                                case "BulletIndent1":
                                    blockFontScales[TextStyleType.BULLET_LIST] = blockFontScales[TextStyleType.BULLET_LIST] || [1, 1];
                                    blockFontScales[TextStyleType.BULLET_LIST][1] = scale;
                                    break;
                            }
                        }
                    }
                };

                for (let userFontScalePath of Object.keys(userFontScale)) {
                    const scale = userFontScale[userFontScalePath];

                    if (this.getRootElement().model.useLegacyNodes) {
                        userFontScalePath = userFontScalePath.replace("NodeElement", "ContentItem");
                    }

                    // fix contentBlockContainer name change to TextFrame
                    userFontScalePath = userFontScalePath.replace("ContentBlockContainer/ContentBlockFrame/ContentBlockCollection", "TextFrame/TextFrameBox");
                    userFontScalePath = userFontScalePath.replace("PresentationTitleDisplayText", "PresentationTitleTextFrame/TextFrameBox");
                    userFontScalePath = userFontScalePath.replace("HeadlineContentBlock/ContentBlockFrame/ContentBlockCollection/ContentBlockItem", "HeadlineTextFrame/TextFrameBox");
                    userFontScalePath = userFontScalePath.replace("ContentBlockBulletPoint/TextGroup/TextGroupTitle", "BulletIndent0");
                    userFontScalePath = userFontScalePath.replace("ContentBlockBulletPoint/TextGroup/TextGroupBody", "BulletIndent1");

                    if (userFontScalePath.includes("Timeline/TimelineItem/TimelineItemInfoBlock/TextGroup")) {
                        processUserFontScalePath(userFontScalePath.replace("Timeline/TimelineItem/TimelineItemInfoBlock/TextGroup", "Timeline/TimelineAnnotations/TextContentItem"), scale);
                        processUserFontScalePath(userFontScalePath.replace("Timeline/TimelineItem/TimelineItemInfoBlock/TextGroup", "Timeline/TimelineAnnotations/MediaAndTextContentItem"), scale);
                    } else {
                        processUserFontScalePath(userFontScalePath, scale);
                    }
                }

                this.model[this.bindTo].blockFontScales = { ...(this.model[this.bindTo].blockFontScales || {}), ...blockFontScales };
            }
        }
    }

    // endregion

    get animationElementName() {
        const text = this.textModel.blocks.reduce((text, block) => `${text}${text !== "" ? " " : ""}${htmlToText(block.html ?? "")}`, "").slice(0, 30);
        return `Text "${text}"`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        if (!this.options.animateBullets) {
            return [{
                name: "Fade in",
                prepare: () => this.animationState.fadeInProgress = 0,
                onBeforeAnimationFrame: progress => {
                    this.animationState.fadeInProgress = progress;
                }
            }];
        }

        const blockGroups = [];
        let currentBlockGroup = [];
        const terminateCurrentBlockGroup = () => {
            if (currentBlockGroup.length === 0) {
                return;
            }
            blockGroups.push(currentBlockGroup);
            currentBlockGroup = [];
        };
        this.textModel.blocks.forEach(block => {
            if (block.listStyle && !block.indent) {
                terminateCurrentBlockGroup();
            }
            currentBlockGroup.push(block);
        });
        terminateCurrentBlockGroup();

        return blockGroups.map((blocks, idx) => ({
            name: `Fade in #${idx + 1}`,
            elementName: `Bullet "${htmlToText(blocks[0].html)}"`,
            prepare: () => {
                blocks.forEach(block => {
                    if (!this.animationState.blocks) {
                        this.animationState.blocks = {};
                    }
                    if (!this.animationState.blocks[block.id]) {
                        this.animationState.blocks[block.id] = {};
                    }
                    this.animationState.blocks[block.id].fadeInProgress = 0;
                });
            },
            onBeforeAnimationFrame: progress => {
                blocks.forEach(block => {
                    this.animationState.blocks[block.id].fadeInProgress = progress;
                });
            }
        }));
    }

    groupTextContent() {
        let textContentGrouped = [], currTextContent = {};
        let isMainTextBlock = false, isSecondaryTextBlock = false, pushCurrentTextContent = false;

        this.blocks.filter(block => block.type === AuthoringBlockType.TEXT).forEach(block => {
            if ([
                TextStyleType.HEADLINE, TextStyleType.HEADING, TextStyleType.TITLE, TextStyleType.BULLET_LIST, TextStyleType.NUMBERED_LIST
            ].includes(block.textStyle) && block.indent === 0) {
                pushCurrentTextContent = isMainTextBlock || isSecondaryTextBlock;
                isMainTextBlock = true; isSecondaryTextBlock = false;
            } else {
                pushCurrentTextContent = false;
                if (!isMainTextBlock && !isSecondaryTextBlock) currTextContent.mainText = { text: "", textStyle: block.textStyle };
                isMainTextBlock = false; isSecondaryTextBlock = true;
            }

            if (pushCurrentTextContent) {
                textContentGrouped.push(currTextContent);
                currTextContent = {};
            }

            if (isMainTextBlock) {
                currTextContent.mainText = { text: block.textContent, textStyle: block.textStyle };
            } else if (isSecondaryTextBlock) {
                if (!currTextContent.secondaryTexts) currTextContent.secondaryTexts = [];
                currTextContent.secondaryTexts.push({ text: block.textContent, textStyle: block.textStyle });
            }
        });

        textContentGrouped.push(currTextContent);
        textContentGrouped = textContentGrouped.map(({ mainText, secondaryTexts }) => ({
            mainText: mainText || { text: "", textStyle: TextStyleType.TITLE }, secondaryTexts: secondaryTexts || []
        }));
        return textContentGrouped;
    }

    _exportToSharedModel(shouldGroupTextContent = false) {
        const mediaBlocks = this.blocks.filter(block => block.type === AuthoringBlockType.MEDIA);
        const textBlocks = this.blocks.filter(block => block.type === AuthoringBlockType.TEXT);

        const assets = mediaBlocks.map(block => ({
            type: block.element.model.content_type,
            value: block.element.model.content_value,
            name: block.element.model.assetName,
            props: block.element.model.assetProps,
            isMediaTextBlock: true
        }));

        const textContent = shouldGroupTextContent ? this.groupTextContent() : [{
            mainText: {
                text: textBlocks[0].textContent,
                textStyle: textBlocks[0].textStyle
            },
            secondaryTexts: textBlocks.slice(1).map(block => ({
                text: block.textContent,
                textStyle: block.textStyle
            }))
        }];

        return { assets, textContent };
    }

    _importFromSharedModel(model) {
        const { textContent } = model;
        if (!textContent?.length) return;

        return {
            [this.bindTo]: {
                blocks: [
                    {
                        html: textContent[0].mainText.text,
                        textStyle: textContent[0].mainText.textStyle || TextStyleType.TITLE,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...textContent[0].secondaryTexts.map(({ text, textStyle }) => ({
                        html: text,
                        textStyle: textStyle || TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }))
                ]
            }
        };
    }

    _migrate_10_01() {
        this.textModel.blocks.forEach(block => {
            // We need to update the block html property if it was set as link to slide.
            // If the element explicity sets the linkToSlideAsLink property to false,
            // we should not update the block html
            if (block.linkToSlide && this.linkToSlideAsLink) {
                block.html = `<a href="bai://${block.linkToSlide}">${block.html}</a>`;
                delete block.linkToSlide;
            }
        });
    }
}

