import React, { Component, Fragment } from "react";
import moment from "moment";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import SelectAll from "mdi-material-ui/SelectAll";
import { Menu, MenuItem } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { _, $ } from "js/vendor";
import {
    SnapLineDirection,
    SnapLineBindingPoint,
    AuthoringElementType,
    AuthoringShapeType,
    SNAP_TOLERANCE,
    AssetType,
    AuthoringBlockType,
    TextStyleType,
    HorizontalAlignType,
    VerticalAlignType,
} from "common/constants";
import * as geom from "js/core/utilities/geom";
import { AnchorType } from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";
import { getStaticUrl } from "js/config";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { PolyLinePath } from "js/core/utilities/shapes";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { SelectionContextMenu } from "./SelectionContextMenu";
import { SelectionBox } from "./SelectionBox";
import { SelectionUI } from "./SelectionUI";
import { getEditorForSelection } from "./Editors/AuthoringEditorsManager";
import { AuthoringControlBar } from "./AuthoringControlBar";
import {
    ClipboardType,
    clipboardRead,
    clipboardWrite,
} from "js/core/utilities/clipboard";
import { isObject, isString } from "lodash";

const ContainerBox = styled.div.attrs(({ bounds, canvasScale }) => ({
    style: {
        left: `${bounds.left * canvasScale}px`,
        top: `${bounds.top * canvasScale}px`,
        width: `${bounds.width * canvasScale}px`,
        height: `${bounds.height * canvasScale}px`
    }
}))`
  position: absolute;
  font-size: 20px;
  pointer-events: none;
  z-index: 10;
`;

const SnapLine = styled.div.attrs(({ bounds, canvasScale }) => ({
    style: {
        left: `${bounds.left * canvasScale}px`,
        top: `${bounds.top * canvasScale}px`,
        width: `${bounds.width * canvasScale}px`,
        height: `${bounds.height * canvasScale}px`
    }
}))`
  position: absolute;
  outline: dashed 1px #ec407a75;
`;

const GridLine = styled.div.attrs(({ bounds, opacity, isHilited, canvasScale }) => ({
    style: {
        left: `${bounds.left * canvasScale}px`,
        top: `${bounds.top * canvasScale}px`,
        width: `${bounds.width * canvasScale}px`,
        height: `${bounds.height * canvasScale}px`,
        opacity,
        outline: isHilited ? "dashed 1.2px rgba(0, 0, 0, 0.2)" : "dashed 1px rgba(0, 0, 0, 0.1)"
    }
}))`
  position: absolute;
  transition: opacity 0.5s;
`;

const DragType = {
    SELECTION: "selection",
    POSITION: "position",
    RESIZE: "resize",
    ROTATE: "rotate"
};

const COPIED_STYLES = [
    "fill",
    "stroke",
    "strokeWidth",
    "strokeStyle",
    "shadow",
    "opacity",
    "textInset",
];

const TABLE_STYLES = [
    "showBorder",
    "showColGridLines",
    "showRowGridLines",
    "showTopLeftCell",
    "alternateRows",
    "tableBackgroundColor"
];

export class AuthoringLayer extends Component {
    constructor() {
        super();

        this.state = {
            selection: [],
            rolloverElements: [],
            snapLines: [],
            gridLines: [],
            isMouseDown: false,
            isDragging: false,
            dragType: null,
            hasDragged: false,
            dragSelectionBounds: null,
            editingElement: null,
            dragCreateModel: null,
            cursor: null,
            showContextMenu: false,
            contextMenuPositionX: 0,
            contextMenuPositionY: 0,
            showGridLines: false,
            hideControlBar: false,
            selectionElement: null
        };

        this.allowDragMove = true;
        this.dragOffset = null;
        this.resizeAnchor = null;
        this.modelBeforeResize = null;
        this.modelBackup = {};
        this.hasPendingModelChanges = false;
        this.mouseMoveHandledAt = null;
        this.mouseDownHandledAt = null;
        this.authoringControlBarBounds = null;

        this.containerBoxRef = React.createRef();
        this.editorRef = React.createRef();
        this.authoringControlBarRef = React.createRef();
    }

    get hasSelection() {
        return !!this.state.selection.length;
    }

    // checks for windows that might be overlaying the editor
    // area which can be used to avoid using certain mouse interactions
    get isOverlayShowing() {
        const overlays = [
            ".overlay--arrange-presentation-slides"
        ];

        return $(overlays.join(", ")).length > 0;
    }

    // checks for popovers that might be overlaying the editor
    // area which can be used to avoid using certain mouse interactions
    get isPopoverShowing() {
        const popovers = [
            ".MuiPopover-root"
        ];

        return $(popovers.join(", ")).length > 0;
    }

    get canGroupSelection() {
        const { selection } = this.state;

        if (selection.length < 2) {
            return false;
        }

        const selectedGroups = Array.from(new Set(selection.map(element => element.groupId)));
        // All belong to one group
        if (selectedGroups.length === 1 && selectedGroups[0]) {
            return false;
        }

        return true;
    }

    get canUngroupSelection() {
        const { selection } = this.state;
        return selection.some(element => element.groupId);
    }

    get selectionHasLockedItems() {
        return this.state.selection.some(element => element.isLocked);
    }

    get snapToGrid() {
        const { containerElement } = this.props;
        return containerElement.snapToGrid;
    }

    get gridSpacing() {
        const { containerElement } = this.props;
        return containerElement.gridSpacing;
    }

    get containerPadding() {
        const { containerElement } = this.props;
        return containerElement.padding;
    }

    get canvas() {
        const { containerElement } = this.props;
        return containerElement.canvas;
    }

    get isCanvasLayouterGenerating() {
        if (!this.canvas.layouter) {
            return false;
        }

        return this.canvas.layouter.isGenerating;
    }

    get isCanvasAnimating() {
        return this.canvas.isAnimating;
    }

    setDragCreateModel = model => {
        this.setState({
            dragCreateModel: model,
            cursor: "move",
            selection: []
        });
    }

    componentDidMount() {
        this.saveModelBackup();

        this.calcGridLines();

        window.addEventListener("keydown", this.handleEvent);
        window.addEventListener("keyup", this.handleEvent);
        window.addEventListener("mousedown", this.handleEvent);
        window.addEventListener("mousemove", this.handleEvent);
        window.addEventListener("mouseup", this.handleEvent);
        ds.selection.on("change:element", this.onSelectionElementChanged);

        // make sure the canSelect state is correctly false for all text elements (this can happen when undo is called)
        for (const element of this.props.containerElement.itemElements) {
            if (element.childElement.text?.blockContainerRef?.current) {
                element.childElement.text.blockContainerRef.current.setState({ canSelect: false });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleEvent);
        window.removeEventListener("keyup", this.handleEvent);
        window.removeEventListener("mousedown", this.handleEvent);
        window.removeEventListener("mousemove", this.handleEvent);
        window.removeEventListener("mouseup", this.handleEvent);
        ds.selection.off("change:element", this.onSelectionElementChanged);
    }

    componentDidUpdate(prevProps, prevState) {
        const { containerElement } = this.props;
        const { selection, hideControlBar, isDragging } = this.state;

        // unselect any text elements that are no longer selected
        for (const element of containerElement.itemElements) {
            if (element?.childElement.text?.blockContainerRef?.current) {
                const canSelect = isDragging ? false : selection.includes(element);
                const blockContainer = element.childElement.text.blockContainerRef.current;
                if (blockContainer.state.canSelect !== canSelect) {
                    blockContainer.setState({ canSelect });
                }
            }
        }

        // Detecting when selection is changed to update the
        // ignoreArrowKeys value of the editor and ds.selection.authoringElements
        const prevSelectionIds = prevState.selection.map(element => element.model.id);
        const selectionIds = selection.map(element => element.model.id);
        if (
            prevSelectionIds.length !== selectionIds.length ||
            prevSelectionIds.some(prevId => !selectionIds.includes(prevId)) ||
            selectionIds.some(id => !prevSelectionIds.includes(id))
        ) {
            ds.selection.authoringElements = selection;
            this.canvas.selectionLayer.preventSlideNavigationOnArrowKeys = selection.length > 0;
        }

        // unselect any text if we are dragging
        if (prevState.isDragging !== isDragging && isDragging == true) {
            if (!ds.selection.element?.findClosestOfType("AnnotationLayer")) ds.selection.element = null;
        }

        // Handling locking/unlocking
        if (this.canvas.isCurrentCanvas) {
            if (selection.length > 0 && (prevState.selection.length === 0 || !this.canvas.isLockedForCollaborators())) {
                this.canvas.lockSlideForCollaborators(30);
            } else if (selection.length === 0 && this.canvas.isLockedForCollaborators()) {
                this.canvas.unlockSlideForCollaborators();
            }
        }

        // Hiding authoring control bar if it overlaps with the editor control bar
        const authoringControlBarBounds = this.authoringControlBarRef.current?.bounds;
        if (authoringControlBarBounds) {
            // Preserving authoring control bar bounds when it's hidden
            this.authoringControlBarBounds = authoringControlBarBounds;
        }

        if (this.authoringControlBarBounds) {
            const editorBarBounds = this.editorRef.current?.controlBarRef?.current?.gridBounds;

            if (editorBarBounds) {
                const shouldHideControlBar = editorBarBounds.inflate(10).intersects(this.authoringControlBarBounds);
                if (shouldHideControlBar !== hideControlBar) {
                    this.setState({ hideControlBar: shouldHideControlBar });
                }
            } else if (hideControlBar) {
                this.setState({ hideControlBar: false });
            }
        }
    }

    onSelectionElementChanged = ({ element: selectionElement }) => {
        this.setState({ selectionElement });

        if (!selectionElement) {
            return;
        }

        // This is ugly but ensures the mousedown event that triggered selection element change
        // gets processed by authoring layer before run do the code below
        // _.defer(() => {
        //     const { containerElement } = this.props;
        //     const { selection } = this.state;
        //
        //     const authoringContainerElement = containerElement.itemElements.find(itemElement => selectionElement === itemElement || selectionElement.isChildOf(itemElement));
        //     if (!authoringContainerElement) {
        //         this.setState({ selection: [] });
        //         return;
        //     }
        //
        //     if (selectionElement.isInstanceOf("TextElement") || !selection.includes(authoringContainerElement)) {
        //         this.setState({ selection: [authoringContainerElement] });
        //     }
        // });
    }

    calcGridLines() {
        const { containerElement } = this.props;

        const gridLines = [];
        for (let x = 0; x < containerElement.bounds.width; x += this.gridSpacing) {
            const shouldHilite = x === this.containerPadding || x === containerElement.bounds.width - this.containerPadding;
            if (shouldHilite) {
                gridLines.push(...[
                    { bounds: { left: x, top: 0, width: 0, height: this.containerPadding } },
                    { bounds: { left: x, top: this.containerPadding, width: 0, height: containerElement.bounds.height - this.containerPadding * 2 }, isHilited: true },
                    { bounds: { left: x, top: containerElement.bounds.height - this.containerPadding, width: 0, height: this.containerPadding } },
                ]);
            } else {
                gridLines.push({
                    bounds: { left: x, top: 0, width: 0, height: containerElement.bounds.height }
                });
            }
        }
        for (let y = 0; y < containerElement.bounds.height; y += this.gridSpacing) {
            const shouldHilite = y === this.containerPadding || y === containerElement.bounds.height - this.containerPadding;
            if (shouldHilite) {
                gridLines.push(...[
                    { bounds: { left: 0, top: y, width: this.containerPadding, height: 0 } },
                    { bounds: { left: this.containerPadding, top: y, width: containerElement.bounds.width - this.containerPadding * 2, height: 0 }, isHilited: true },
                    { bounds: { left: containerElement.bounds.width - this.containerPadding, top: y, width: this.containerPadding, height: 0 } }
                ]);
            } else {
                gridLines.push({
                    bounds: { left: 0, top: y, width: containerElement.bounds.width, height: 0 }
                });
            }
        }

        this.setState({ gridLines });
    }

    /**
     * Please use this instead of element.selectionBounds in order to keep bounds in sync with model
     */
    getElementSelectionBounds = elementContainer => {
        const { containerElement } = this.props;

        if (elementContainer === containerElement) {
            return this.getContainerBounds();
        }

        return new geom.Rect(elementContainer.model.x, elementContainer.model.y, elementContainer.model.width, elementContainer.model.height);
    }

    /**
     * Preserves the correct order of elements, puts header and footer behind
     */
    getElements = () => {
        const { containerElement } = this.props;

        return containerElement.model.elements
            .map(({ id }) => containerElement.elements[id])
            // Make sure element is initialized/rendered
            .filter(element => element)
            // Reversing so the first element is on top
            // .reverse()
            // Putting header and footer behind to always allow selecting other elements over/behind them
            .sort((a, b) =>
                ([AuthoringElementType.FOOTER, AuthoringElementType.HEADER].includes(a.model.type) ? 1 : 0) -
                ([AuthoringElementType.FOOTER, AuthoringElementType.HEADER].includes(b.model.type) ? 1 : 0)
            );
    }

    getElementsInVisualOrder() {
        return this.getElements()
            .map(el => {
                // this is a very simple way to help prioritize left to right, with the top having some
                // bearing on ordering. After trying several more complicated ways, this works well
                // without a bunch of added complexity
                const { left, top } = el.calculatedProps.bounds;
                const priority = Math.sqrt(left + (top / (left || 1)));

                return { el, priority };
            })
            .sort((a, b) => a.priority - b.priority)
            .map(el => el.el);
    }

    getNotSelectedElements = () => {
        const { containerElement } = this.props;
        const { selection } = this.state;

        return [
            ...this.getElements().filter(element => !selection.includes(element)),
            containerElement
        ];
    }

    getElementsAtPoint(selectionPoint) {
        return this.getHitElements(selectionPoint);
    }

    getHitElements = function(pt) {
        let cl = this.canvas.$el.offset().left;
        let ct = this.canvas.$el.offset().top;
        let canvasScale = this.canvas.canvasScale;

        // var mx = pt.x - cl;
        // var my = e.pageY - ct;
        let mx = pt.x;
        let my = pt.y;
        var hitElements = [];

        for (let element of this.getElements()) {
            if (element.model.rotation && element.model.rotation % 360 !== 0) {
                let matrix = [];
                let matrixVal = $(element.ref.current.ref.current).css("transform");

                if (matrixVal != "none") {
                    var matrixParsed = matrixVal.substr(7, matrixVal.length - 8).split(",");
                    for (var i in matrixParsed) matrix[i] = parseFloat(matrixParsed[i]);
                } else {
                    matrix = [1, 0, 0, 1, 0, 0];
                }

                var hW = element.model.width / 2; //Half of width
                var hH = element.model.height / 2; //Half of height
                var o = {
                    x: element.model.x + hW,
                    y: element.model.y + element.model.height / 2
                }; //Transform origin

                //Define shape points and transform by matrix
                var p1 = {
                    x: o.x + matrix[0] * -hW + matrix[2] * -hH + matrix[4],
                    y: o.y + matrix[1] * -hW + matrix[3] * -hH + matrix[5]
                }; //Left top

                var p2 = {
                    x: o.x + matrix[0] * +hW + matrix[2] * -hH + matrix[4],
                    y: o.y + matrix[1] * +hW + matrix[3] * -hH + matrix[5]
                }; //Right top

                var p3 = {
                    x: o.x + matrix[0] * +hW + matrix[2] * +hH + matrix[4],
                    y: o.y + matrix[1] * +hW + matrix[3] * +hH + matrix[5]
                }; //Right bottom

                var p4 = {
                    x: o.x + matrix[0] * -hW + matrix[2] * +hH + matrix[4],
                    y: o.y + matrix[1] * -hW + matrix[3] * +hH + matrix[5]
                }; //Left bottom

                //Calculate edge normal vectors & C vars
                var v1 = { x: -(p2.y - p1.y), y: (p2.x - p1.x) }; //Top
                var v2 = { x: -(p3.y - p2.y), y: (p3.x - p2.x) }; //Right
                var v3 = { x: -(p4.y - p3.y), y: (p4.x - p3.x) }; //Bottom
                var v4 = { x: -(p1.y - p4.y), y: (p1.x - p4.x) }; //Left

                var c1 = -(v1.x * p1.x + v1.y * p1.y);
                var c2 = -(v2.x * p2.x + v2.y * p2.y);
                var c3 = -(v3.x * p3.x + v3.y * p3.y);
                var c4 = -(v4.x * p4.x + v4.y * p4.y);

                //Check cursor distance from edge using general line quation: ax + by + c = 0
                var isInner = function(v, c, x, y) {
                    return (v.x * x + v.y * y + c) / Math.sqrt(v.x * v.x + v.y * v.y) > 0;
                };

                //Check if mouse point is in shape coords using general line equation
                if (isInner(v1, c1, mx, my) && isInner(v2, c2, mx, my) && isInner(v3, c3, mx, my) && isInner(v4, c4, mx, my)) {
                    hitElements.push(element);
                }
            } else {
                if (element.containsPoint(pt, this.state.selection.contains(element))) {
                    hitElements.push(element);
                }
            }
        }

        return hitElements;
    }

    getElementsInRect(selectionBounds) {
        return this.getElements()
            .filter(element => {
                if (element.model.type == AuthoringElementType.SHAPE && !element.childElement.isTextBox && element.model.text.blocks.length == 0 && element.model.shape == "rect" && element.childElement.fill == "none") {
                    let bounds = this.getElementSelectionBounds(element);
                    if (selectionBounds.intersectsLine(bounds.left, bounds.top, bounds.left, bounds.bottom).any) {
                        return true;
                    } else if (selectionBounds.intersectsLine(bounds.left, bounds.top, bounds.right, bounds.top).any) {
                        return true;
                    } else if (selectionBounds.intersectsLine(bounds.right, bounds.top, bounds.right, bounds.bottom).any) {
                        return true;
                    } else if (selectionBounds.intersectsLine(bounds.left, bounds.bottom, bounds.right, bounds.bottom).any) {
                        return true;
                    }
                } else {
                    return this.getElementSelectionBounds(element).intersects(selectionBounds);
                }
            });
    }

    getGroupElements(element) {
        if (!element.groupId) {
            return [element];
        }

        return this.getElements().filter(el => el.groupId === element.groupId);
    }

    groupSelection = () => {
        if (!this.canGroupSelection) {
            return;
        }

        const { selection } = this.state;

        const groupId = uuid();
        selection.forEach(element => element.groupId = groupId);

        this.refreshCanvasAndSaveChanges();
    }

    ungroupSelection = () => {
        if (!this.canUngroupSelection) {
            return;
        }
        const { selection } = this.state;
        selection.forEach(element => element.groupId = null);

        this.refreshCanvasAndSaveChanges()
            .then(() => this.setState({ selection: [] }));
    }

    toggleLock = () => {
        // if any of the selected item are locked, default to unlocked
        const hasLockedElements = this.state.selection.find(element => element.model.isLocked);
        if (hasLockedElements) {
            this.unlockSelection();
        } else {
            this.lockSelection();
        }
    }

    lockSelection = async () => {
        this.state.selection.forEach(element => element.model.isLocked = true);
        ds.selection.element = null;
        await this.refreshCanvasAndSaveChanges();
    }

    unlockSelection = async () => {
        this.state.selection.forEach(element => element.model.isLocked = false);
        await this.refreshCanvasAndSaveChanges();
    }

    toggleSnapToGrid = () => {
        const { containerElement } = this.props;
        containerElement.model.snapToGrid = !this.snapToGrid;
        return this.refreshCanvasAndSaveChanges();
    }

    selectElement = element => {
        this.setState({
            selection: [element],
            showContextMenu: false
        });
    }

    clearSelection = () => {
        this.setState({ selection: [] });
    }

    startEditing = element => {
        if (this.selectionHasLockedItems) {
            return;
        }

        this.setState({
            editingElement: element
        });

        if (!element.childElement.isAuthoringElement) {
            ds.selection.element = element.childElement;
        }
    }

    stopEditing = () => {
        this.setState({
            editingElement: null
        });
    }

    removeSelectedElements = async () => {
        const { containerElement } = this.props;
        const { selection } = this.state;

        if (selection.some(element => element.model.isLocked)) {
            if (!await ShowConfirmationDialog({ title: "Are you sure you want to delete locked elements?" })) {
                return;
            }
        }

        await new Promise(resolve =>
            this.setState({
                selection: [],
                rolloverElements: []
            }, resolve)
        );

        for (const element of selection) {
            containerElement.model.elements.remove(element.model);

            if (this.getGroupElements(element).length == 1) {
                this.getGroupElements(element)[0].groupId = null;
            }
        }

        await this.refreshCanvasAndSaveChanges();
    }

    getElementsSelectionBounds = elements => {
        let selectionBounds;
        for (const element of elements) {
            if (!selectionBounds) {
                selectionBounds = this.getElementSelectionBounds(element);
            } else {
                selectionBounds = selectionBounds.union(this.getElementSelectionBounds(element));
            }
        }
        return selectionBounds;
    }

    getPositionFromEvent = event => {
        const { containerElement } = this.props;

        const containerBoundingBox = this.containerBoxRef.current.getBoundingClientRect();

        return new geom.Point(
            (event.pageX - containerBoundingBox.left) / this.canvas.canvasScale - containerElement.selectionBounds.left,
            (event.pageY - containerBoundingBox.top) / this.canvas.canvasScale - containerElement.selectionBounds.top);
    }

    getContainerBoundsOffset = () => {
        const { containerElement } = this.props;

        return new geom.Point(containerElement.selectionBounds.left, containerElement.selectionBounds.top);
    }

    /**
     * WARNING: offsets the actual container bounds to 0, 0
     */
    getContainerBounds = () => {
        const { containerElement } = this.props;

        return new geom.Rect(0, 0, containerElement.selectionBounds.width, containerElement.selectionBounds.height);
    }

    /**
     * WARNING: offsets the actual container bounds to 0, 0
     */
    getPaddedContainerBounds = () => {
        return this.getContainerBounds().deflate(this.containerPadding);
    }

    saveModelBackup = () => {
        for (const element of this.getElements()) {
            this.modelBackup[element.model.id] = _.cloneDeep(element.model);
        }
    }

    restoreModelBackup = () => {
        for (const element of this.getElements()) {
            Object.entries(this.modelBackup[element.model.id]).forEach(([key, value]) => {
                element.model[key] = value;
            });
        }
    }

    /**
     * Returns a boolean value that indicates if model was reverted due to canvas not fit
     */
    refreshCanvas = async () => {
        let modelWasReverted = false;

        this.props.containerElement.markStylesAsDirty();

        try {
            await this.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
        } catch (err) {
            // Layout didn't fit, restoring backup and refreshing canvas again
            this.restoreModelBackup();
            modelWasReverted = true;
            await this.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
        }

        if (!modelWasReverted) {
            this.saveModelBackup();
        }

        return modelWasReverted;
    }

    /**
     * Returns a boolean value that indicates if model was reverted due to canvas not fit
     */
    refreshElement = (forceUpdate = true) => {
        const { containerElement } = this.props;

        let modelWasReverted = false;

        try {
            containerElement.refreshElement();
        } catch (err) {
            // Layout didn't fit, restoring backup and refreshing canvas again
            this.restoreModelBackup();
            modelWasReverted = true;
            containerElement.refreshElement();
        }

        if (!modelWasReverted) {
            this.saveModelBackup();
        }

        if (forceUpdate) {
            this.forceUpdate();
        }

        return modelWasReverted;
    }

    saveChanges = () => this.canvas.saveCanvasModel();

    refreshCanvasAndSaveChanges = async () => {
        await this.refreshCanvas();
        this.forceUpdate();

        // Won't wait for the actual model save bacause the UI doesn't depend on it
        this.saveChanges().then(() => this.hasPendingModelChanges = false);
    }

    /**
     * Please use this method for handling all events
     */
    handleEvent = event => {
        if (!this.containerBoxRef.current || !this.containerBoxRef.current.offsetParent) {
            return;
        }

        if (this.isCanvasLayouterGenerating) {
            return;
        }

        if (this.isCanvasAnimating) {
            return;
        }

        if (app.dialogManager.openDialogs.length >= 1 && !app.dialogManager.openDialogs.some(d => (
            ["SpeakerNotes", "AnimationDialog", "AnimationPanel"].includes(d.type)
        ))) {
            return;
        }

        const { type } = event;

        if (type === "contextmenu") {
            return this.handleContextMenu(event);
        }

        if (type === "keydown") {
            return this.handleKeyDown(event);
        }

        if (type === "keyup") {
            return this.handleKeyUp(event);
        }

        if (type === "mousedown") {
            return this.handleMouseDown(event);
        }

        if (type === "mousemove") {
            return this.handleMouseMove(event);
        }

        if (type === "mouseup") {
            return this.handleMouseUp(event);
        }
    }

    handleKeyDown = async event => {
        const { selection, editingElement, isDragging } = this.state;
        const key = event.which;

        // handle cycling through fields
        if (key === Key.TAB) {
            const elements = this.getElementsInVisualOrder();
            let index = 0;

            // something is selected, we need to try and
            // figure out which one is next
            if (this.state.selection) {
                const direction = event.shiftKey ? -1 : 1;
                const { length: total } = elements;
                index = elements.indexOf(this.state.selection[0]);
                index = (index + direction + total) % total;
            }

            // replace the selection
            const selection = [elements[index]];
            this.setState({ selection });
            event.preventDefault();
            return;
        }

        if (editingElement) return;
        if (event.target.nodeName === "TEXTAREA") return;
        if (event.target.nodeName === "INPUT") return;
        if (event.target.nodeName === "DIV" && event.target.getAttribute("contenteditable") === "true") return;

        if (key == Key.ALT && isDragging) {
            this.createDragCopy();
        }

        // Canvas hotkeys
        if ((event.ctrlKey || event.metaKey)) {
            switch (key) {
                case Key.KEY_A:
                    event.preventDefault();
                    this.selectAll();
                    break;
                case Key.KEY_G:
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.ungroupSelection();
                    } else {
                        this.groupSelection();
                    }
                    break;
                case Key.KEY_D:
                    event.preventDefault();
                    if (selection.length > 0) {
                        this.duplicateSelection();
                    }
                    break;
                case Key.OPEN_BRACKET:
                    event.preventDefault();
                    this.sendSelectionToBack();
                    break;
                case Key.CLOSE_BRACKET:
                    event.preventDefault();
                    this.bringSelectionToFront();
                    break;
                case Key.KEY_C:
                case Key.KEY_X:
                    event.preventDefault();
                    this.copyToClipboard(event.which == Key.KEY_X);
                    break;
                case Key.KEY_L:
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.toggleLock();

                        // do not do the normal event -- In Safari this
                        // opens the bookmark tab
                        event.stopPropagation();
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                    break;
            }
            return;
        }

        // create shortcuts
        if (selection.length == 0) {
            switch (key) {
                case Key.KEY_R:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.SHAPE,
                            shape: AuthoringShapeType.RECT
                        }
                    });
                    break;
                case Key.KEY_O:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.SHAPE,
                            shape: AuthoringShapeType.ELLIPSE
                        }
                    });
                    break;
                case Key.KEY_P:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.PATH
                        }
                    });
                    break;
                case Key.KEY_T:
                    this.setState({
                        dragCreateModel: {
                            type: AuthoringElementType.SHAPE,
                            shape: AuthoringShapeType.RECT,
                            fill: "none",
                            stroke: "none",
                            textAlign: HorizontalAlignType.LEFT,
                            verticalAlign: VerticalAlignType.TOP,
                            fitToText: true,
                            text: {
                                blocks: [{
                                    id: uuid(),
                                    type: AuthoringBlockType.TEXT,
                                    textStyle: TextStyleType.TITLE,
                                    html: ""
                                }]
                            }
                        }
                    });
                    break;
            }
        }

        if (key == Key.ESCAPE && this.state.dragCreateModel) {
            this.setState({ dragCreateModel: null, cursor: null });
            return;
        }

        // Selection modifier keys
        if (selection.length === 0) {
            return;
        }

        switch (key) {
            case Key.DELETE:
            case Key.BACKSPACE:
                this.removeSelectedElements();
                break;
            case Key.RIGHT_ARROW:
            case Key.LEFT_ARROW:
            case Key.UP_ARROW:
            case Key.DOWN_ARROW:
                const nudge = event.shiftKey ? 10 : 1;
                for (const element of selection) {
                    if (!element.isLocked) {
                        if (key === Key.UP_ARROW) {
                            element.model.y -= nudge;
                        } else if (key === Key.DOWN_ARROW) {
                            element.model.y += nudge;
                        } else if (key === Key.RIGHT_ARROW) {
                            element.model.x += nudge;
                        } else if (key === Key.LEFT_ARROW) {
                            element.model.x -= nudge;
                        }
                    }
                }
                this.refreshCanvasAndSaveChanges();
                break;
            default:
                // Create block into shape without any text on first keypress
                if (selection.length == 1 && this.editorRef && this.editorRef.current && this.editorRef.current.createInitialTextBlock) {
                    if (!["Shift", "Ctrl", "Alt"].includes(event.key) && selection[0].model.text.blocks.length === 0) {
                        this.editorRef.current.createInitialTextBlock();
                    }
                }
        }
    }

    handleKeyUp = event => {
        const { isDragging } = this.state;
        const key = event.which;

        if (key == Key.ALT && isDragging && this.isCopyDragging) {
            this.destroyDragCopy();
        }
    }

    handleContextMenu = event => {
        event.preventDefault();
        event.stopPropagation();

        if (this.isOverlayShowing) {
            return;
        }

        this.setState({
            showContextMenu: true,
            contextMenuPositionX: event.pageX,
            contextMenuPositionY: event.pageY
        });
    }

    handleMouseDown = event => {
        const { dragCreateModel, selection, editingElement } = this.state;
        const isDragCreatingElement = dragCreateModel !== null;

        if (PresentationEditorController.allowAuthoringEvents()) return;

        if ($(event.target).closest("button, .MuiListItem-button, .control_bar").length) {
            return;
        }

        if (event.button !== 0 && event.button !== 2) {
            return;
        }

        if (
            $(event.target).is("adj-handle, .editor-control, .editor-control-bar, .ui-popover, .MuiPopover-root") ||
            $(event.target).closest(".editor-control, .editor-control-bar, .ui-popover").length > 0
        ) {
            // Special case to avoid Safari removing text selection when clicked
            // on a click-away listener of a popup
            if (event.target?.getAttribute("aria-hidden") === "true") {
                event.preventDefault();
            }
            return;
        }

        this.lastDraggedOffset = null;

        const clickPosition = this.getPositionFromEvent(event);

        // check for context menus
        if (event.button === 2) {
            if (this.isOverlayShowing) {
                return;
            }

            // tentatively mark the context menu as visible
            this.setState({
                showContextMenu: true,
                contextMenuPositionX: event.pageX,
                contextMenuPositionY: event.pageY
            });
        } else {
            // not requesting the context menu
            this.setState({
                showContextMenu: false
            });
        }

        let passthroughClick = event.ctrlKey || event.metaKey;
        let isDoubleClick = false;
        if (event.button === 0) {
            const timestamp = moment().valueOf();
            isDoubleClick = this.mouseDownHandledAt && timestamp - this.mouseDownHandledAt < 300;
            this.mouseDownHandledAt = timestamp;
        }

        let clickedElementGroup = null;
        let elementsInClickBounds = this.getElementsAtPoint(clickPosition).reverse();

        // if the click isn't over an element, but it is over the selection box
        // for example, a space between elements, then reuse the current selection
        if (!elementsInClickBounds.length && this.state.selection.length) {
            const bounds = this.getElementsSelectionBounds(this.state.selection);
            if (bounds.contains(clickPosition)) {
                elementsInClickBounds = [...this.state.selection];
            }
        }

        this.setState({ elementsUnderMouse: elementsInClickBounds });

        if (elementsInClickBounds.length > 0) {
            let clickedElement = elementsInClickBounds[0];

            if (clickedElement.groupId && selection.length == 1 && selection[0].groupId == clickedElement.groupId) {
                // if we have selected a single element within a group via double-clicking, allow selection of sibling elements in the group
                clickedElementGroup = [clickedElement];
            } else if ((isDoubleClick || passthroughClick) && clickedElement.groupId) {
                // allow double-click to directly select an element in a group
                this.setState({
                    selection: [clickedElement]
                });
                return; // early out because we don't allow dragging or editing from this action
            } else {
                // select the element and it's grouped siblings (if any)
                clickedElementGroup = this.getGroupElements(clickedElement);
            }
        }

        let elements = [];
        if (clickedElementGroup) {
            const clickedElementIsInSelection = selection.some(element => clickedElementGroup.includes(element));

            // holding shift, but not pressing alt, allows a user to start a
            // duplication action and have the axis constrained. Otherwise,
            // this may deselect the layer they're working with
            if (event.shiftKey && !event.altKey && event.button === 0) {
                if (clickedElementIsInSelection) {
                    elements = selection.filter(element => !clickedElementGroup.includes(element));
                } else {
                    elements = [...selection, ...clickedElementGroup];
                }
            } else {
                if (clickedElementIsInSelection) {
                    elements = selection;
                } else {
                    elements = clickedElementGroup;
                }
            }
        }

        // User wants to reposition or copy or select
        if (elements.length > 0 && !isDragCreatingElement) {
            if (elements.length === 1 && editingElement === elements[0]) {
                // Ignore mousedown on the editingElement
                return;
            }

            // Handle double clicks to start editing if available
            if (elements.length === 1 && isDoubleClick) {
                // Only want to select the element when double clicked on it
                if (selection.length === 1 && selection[0] === elements[0]) {
                    if (selection[0].childElement.isAuthoringElement) {
                        if (selection[0].childElement.allowEditingByDoubleClick) {
                            this.setState({
                                editingElement: selection[0]
                            });
                            return;
                        }
                        // Select the child's assetElement if it has one
                        const assetElement = selection[0].childElement.content?.assetElement;
                        if (assetElement && assetElement.model.content_type !== "icon") {
                            ds.selection.element = assetElement;
                        }
                    } else {
                        // Select the child's assetElement if it has one
                        const assetElement = selection[0].childElement.content?.assetElement;
                        const selectionElement = (assetElement && assetElement.model.content_type !== "icon") ? assetElement : selection[0].childElement;

                        this.setState({
                            editingElement: selection[0]
                        });

                        // Set selection layer selection for non-authoring elements on double-click and prevent text getting the mouse down event
                        event.stopPropagation();
                        event.preventDefault();
                        ds.selection.element = selectionElement;
                        return;
                    }
                }
            }

            const selectionBounds = this.getElementsSelectionBounds(elements);
            this.dragOffset = new geom.Point(clickPosition.x - selectionBounds.left, clickPosition.y - selectionBounds.top);

            // remove any locked elements from selection unless right or double-click
            if (event.button == 0 && !isDoubleClick) {
                elements = elements.filter(element => !element.isLocked || (element.model.type == AuthoringElementType.SHAPE && element.childElement?.hasText));
            }

            this.setState({
                selection: elements,
                selectionBoundsBeforeDrag: selectionBounds,
                isMouseDown: true,
                editingElement: null,
                mouseDownPoint: new geom.Point(event.pageX, event.pageY)
            });

            // If option is pressed, we don't want to copy the elements immediately upon the click,
            // they'll be copied once (and if) user drags them
            if (elements.length) {
                // if (event.altKey) {
                //     this.createDragCopy();
                // }
                // this.startDrag(DragType.POSITION);
                return;
            }
        }

        if (editingElement) {
            this.setState({
                selection: [],
                editingElement: null,
                dragType: null
            });
        } else {
            // User wants to multiselect or drag create
            const dragSelectionBounds = new geom.Rect(clickPosition.x, clickPosition.y, 1, 1);

            this.setState({
                // selection: [],
                snapLines: [],
                isMouseDown: true,
                dragSelectionBounds,
                editingElement: null
            });

            if (isDragCreatingElement || !event.shiftKey) {
                this.setState({
                    selection: []
                });
            }

            // Since there is a dragCreateModel defined, drag create the new element
            if (isDragCreatingElement) {
                this.startDragCreate(event);
            } else {
                // Start a drag selection
                this.startDrag(DragType.SELECTION);
            }
        }
    }

    startDrag = dragType => {
        this.lastDraggedOffset = new geom.Point(0, 0);
        this.setState({
            isDragging: true,
            dragType,
            selectedElementsOnDragSelection: _.clone(this.state.selection),
            showGridLines: [DragType.RESIZE, DragType.POSITION].includes(dragType) && this.snapToGrid
        });
    }

    handleMouseUp = () => {
        const { isMouseDown, dragPosition, selection } = this.state;
        if (!isMouseDown) {
            return;
        }

        if (!this.isCopyDragging) {
            this.lastDraggedOffset = null; // after copy drag, we preserve this for cmd-d
        }
        this.isCopyDragging = false;

        // need to disable interactions immediately
        this.setState({
            isDragging: false,
            hasDragged: false,
            dragType: null,
            isMouseDown: false,
            showGridLines: false,
            snapLines: [],
            dragSelectionBounds: null,
            dragCreateModel: null,
            cursor: null,
            selectedElementsOnDragSelection: null,
            mouseDownPoint: null
        });

        // this code previously would call refreshCanvas instead of promise.resolve
        // Not sure why we needed to refreshCanvas when hasPendingModelChanges was false
        // this was preventing double clicks from triggering when there were a lot of charts on a slide
        (this.hasPendingModelChanges ? this.refreshCanvasAndSaveChanges() : Promise.resolve());
    }

    handleMouseMove = event => {
        // Avoid handling mouse move when there's a popover
        if (this.isPopoverShowing) return;

        const rolloverElements = [];
        const { isMouseDown, isDragging, selection, mouseDownPoint } = this.state;

        if (!isMouseDown) {
            const elementsUnderCursor = this.getElementsAtPoint(this.getPositionFromEvent(event));
            elementsUnderCursor.sort((a, b) => b.calculatedProps.layer - a.calculatedProps.layer);

            // get the first not-locked layer
            // const overElement = elementsUnderCursor.find(element => !element.isLocked);
            const overElement = elementsUnderCursor[0];

            // check if the layer being hovered is part of the same group
            const partOfSameGroup = selection.length && selection[0]?.model?.groupIds?.[0] === overElement?.model?.groupIds?.[0];

            // if this is a child of a group
            if (event.ctrlKey || event.metaKey || partOfSameGroup) {
                // check to focus on a single element
                if (overElement && !overElement.groupIds?.[0] && !selection.find(selected => selected.id === overElement.id)) {
                    rolloverElements.push(overElement);
                }

                // replace the hover elements
                this.setState({ rolloverElements });
                return;
            } else {
                // check if part of a group
                const over = elementsUnderCursor.find(element => !element.isLocked || (element.model.type == AuthoringElementType.SHAPE && element.childElement?.hasText));

                // inside of a group
                if (over?.model?.groupIds?.length) {
                    // merge all
                    const [id] = over.model.groupIds;
                    const layers = this.getElements().filter(element => element.model?.groupIds?.[0] === id);
                    const group = new SelectionGroup(layers);
                    this.setState({ rolloverElements: [group] });

                    return;
                } else if (over) {
                    this.setState({ rolloverElements: [over] });
                    return;
                }
            }

            // if the item is inside of a group, hover the whole group
            this.setState({ rolloverElements: [] });
            return;
        }

        if (isDragging) {
            this.handleMouseMoveOnDrag(event);
        } else {
            if (mouseDownPoint?.distance(new geom.Point(event.pageX, event.pageY)) > 5) {
                if (event.altKey) {
                    this.createDragCopy();
                }
                this.startDrag(DragType.POSITION);
            }
        }
    }

    handleMouseMoveOnDrag = event => {
        const { dragType, mouseDownPoint } = this.state;

        // Making dragging smoother
        window.requestAnimationFrame(timestamp => {
            // It's possible that we stopped dragging while were waiting for the next frame
            const { isDragging, hasDragged } = this.state;
            if (!isDragging) {
                return;
            }

            if (this.mouseMoveHandledAt === timestamp) {
                return;
            }
            if (this.isCanvasLayouterGenerating) {
                return;
            }

            this.mouseMoveHandledAt = timestamp;

            if (!hasDragged) {
                this.setState({ hasDragged: true });
            }

            if (dragType === DragType.POSITION && new geom.Point(event.pageX, event.pageY).distance(mouseDownPoint) > (5 / this.canvas.canvasScale)) {
                return this.handleDragPosition(event);
            }

            if (dragType === DragType.SELECTION) {
                return this.handleDragSelection(event);
            }

            if (dragType === DragType.RESIZE) {
                return this.handleDragResize(event);
            }

            if (dragType === DragType.ROTATE) {
                return this.handleDragRotate(event);
            }
        });
    }

    getSnapToGridOffset = coordinate => {
        const offset = coordinate % this.gridSpacing;
        if (offset >= this.gridSpacing / 2) {
            return this.gridSpacing - offset;
        }
        return -offset;
    }

    createDragCopy = () => {
        const { selection } = this.state;
        const { containerElement } = this.props;

        if (this.isCopyDragging) return;

        let newModels = this.duplicateElementModels(selection).reverse();

        this.copiedElements = [];

        for (let i = 0; i < selection.length; i++) {
            let dragModel = selection[i].model;
            let copyModel = newModels[i];

            let layer = containerElement.model.elements.indexOf(dragModel);
            containerElement.model.elements.insert(copyModel, layer + 1);

            this.copiedElements.push(dragModel);
        }

        this.isCopyDragging = true;
        this.hasPendingModelChanges = true;

        this.refreshCanvas()
            .then(() => {
                for (let element of selection) {
                    element.model.x -= this.lastDraggedOffset.x;
                    element.model.y -= this.lastDraggedOffset.y;
                }

                const newSelection = this.getElements().filter(element => newModels.some(({ id }) => element.model.id === id));
                this.setState({ selection: newSelection });

                this.refreshCanvasAndSaveChanges();
            });
    }

    destroyDragCopy = () => {
        const { containerElement } = this.props;

        if (!this.isCopyDragging) return;
        this.isCopyDragging = false;

        for (let model of this.copiedElements) {
            containerElement.model.elements.remove(model);
        }
        this.hasPendingModelChanges = true;
        this.refreshCanvasAndSaveChanges();
    }

    handleDragPosition(event) {
        const { selection, mouseDownPoint, selectionBoundsBeforeDrag } = this.state;
        const dragOffset = this.dragOffset;

        let simulatedEvent = { pageX: event.pageX, pageY: event.pageY };
        if (event.shiftKey) {
            if (Math.abs(event.pageX - mouseDownPoint.x) < Math.abs(event.pageY - mouseDownPoint.y)) {
                simulatedEvent.pageX = mouseDownPoint.x;
            } else {
                simulatedEvent.pageY = mouseDownPoint.y;
            }
        }

        const dragPosition = this.getPositionFromEvent(simulatedEvent);

        const selectionBounds = this.getElementsSelectionBounds(selection);
        const newSelectionPosition = new geom.Point(dragPosition.x - dragOffset.x, dragPosition.y - dragOffset.y);

        let diffX = newSelectionPosition.x - selectionBounds.left;
        let diffY = newSelectionPosition.y - selectionBounds.top;

        if (diffX === 0 && diffY === 0) {
            return;
        }

        for (const element of selection.filter(el => !el.isLocked)) {
            element.model.x = Math.round(element.model.x + diffX);
            element.model.y = Math.round(element.model.y + diffY);

            if (this.snapToGrid) {
                element.model.x += this.getSnapToGridOffset(element.model.x);
                element.model.y += this.getSnapToGridOffset(element.model.y);
            }
        }

        this.lastDraggedOffset = this.getElementsSelectionBounds(selection).position.minus(selectionBoundsBeforeDrag.position);

        const snapLines = [];
        if (!this.snapToGrid && !event.metaKey) {
            const selectionSnapLines = this.getSnapLines(selection, true);
            const destinationSnapLines = this.getSnapLines(this.getNotSelectedElements(), false);
            const { horizontalSnapLine, verticalSnapLine } = this.calcSnap(selectionSnapLines, destinationSnapLines);
            if (horizontalSnapLine || verticalSnapLine) {
                for (const element of selection) {
                    if (verticalSnapLine) {
                        element.model.x += verticalSnapLine.offset;
                        snapLines.push(verticalSnapLine);
                    }
                    if (horizontalSnapLine) {
                        element.model.y += horizontalSnapLine.offset;
                        snapLines.push(horizontalSnapLine);
                    }
                }
            }
        }

        this.hasPendingModelChanges = true;

        const modelWasReverted = this.refreshElement(false);
        if (!modelWasReverted) {
            this.setState({ snapLines });
        }
    }

    handleDragSelection(event) {
        const { dragSelectionBounds, selectedElementsOnDragSelection } = this.state;
        if (!dragSelectionBounds) {
            return;
        }

        const dragPosition = this.getPositionFromEvent(event);
        // Preserving left and top of dragSelectionBounds in order to avoid
        // creating additional variable for storing the initial drag position
        // So dragSelectionBounds may have negative width or heigth so we have
        // to normalize() it before doing calculations
        const newDragSelectionBounds = new geom.Rect(dragSelectionBounds.left, dragSelectionBounds.top, dragPosition.x - dragSelectionBounds.left, dragPosition.y - dragSelectionBounds.top);

        this.setState({
            dragSelectionBounds: newDragSelectionBounds
        });

        if (Math.abs(newDragSelectionBounds.width) < 5 && Math.abs(newDragSelectionBounds.height) < 5) {
            return;
        }

        const elements = this.getElements();
        let elementsInSelection = this.getElementsInRect(newDragSelectionBounds.normalize());
        elementsInSelection = elementsInSelection.filter(element => !element.isLocked);

        const selectedGroupIds = Array.from(new Set(elementsInSelection.map(element => element.groupId))).filter(groupId => groupId);

        let dragSelectedElements = elements.filter(element => elementsInSelection.includes(element) || selectedGroupIds.includes(element.groupId));

        let selection;
        if (event.shiftKey) {
            selection = [...selectedElementsOnDragSelection];
            for (let element of dragSelectedElements) {
                if (selectedElementsOnDragSelection.contains(element)) {
                    selection.remove(element);
                } else {
                    selection.push(element);
                }
            }
        } else {
            selection = dragSelectedElements;
        }

        this.setState({
            selection: selection,
        });
    }

    onRotateStarted = (event, anchor) => {
        let { selection } = this.state;

        let centerPoint = selection[0].canvasBounds.center;
        this.startAngle = new geom.Point(event.pageX, event.pageY).angleToPoint(centerPoint);
        this.startRotation = selection[0].model.rotation ?? 0;

        this.setState({
            isMouseDown: true,
            cursor: `url(${getStaticUrl("/images/cursors/rotate.svg")}) 5 5, auto`
        });

        this.startDrag(DragType.ROTATE);
    }

    handleDragRotate = event => {
        let { selection } = this.state;

        let centerPoint = selection[0].canvasBounds.center;
        let angle = new geom.Point(event.pageX, event.pageY).angleToPoint(centerPoint);

        let rotate = (this.startRotation + angle - this.startAngle) % 360;
        if (rotate < 0) {
            rotate = 360 + rotate;
        }

        if (event.shiftKey) {
            rotate = Math.ceil(rotate / 22.5) * 22.5;
        }

        selection[0].model.rotation = rotate;

        this.refreshElement(false);
        this.forceUpdate();
    }

    onResizeStarted = (event, anchor) => {
        const { containerElement } = this.props;

        this.resizeAnchor = anchor;
        this.modelBeforeResize = _.cloneDeep(containerElement.model);

        let cursor;
        switch (anchor) {
            case AnchorType.TOP_LEFT:
            case AnchorType.BOTTOM_RIGHT:
                cursor = "nwse-resize";
                break;
            case AnchorType.TOP_RIGHT:
            case AnchorType.BOTTOM_LEFT:
                cursor = "nesw-resize";
                break;
            case AnchorType.LEFT:
            case AnchorType.RIGHT:
                cursor = "ew-resize";
                break;
            case AnchorType.TOP:
            case AnchorType.BOTTOM:
                cursor = "ns-resize";
                break;
        }

        this.setState({
            isMouseDown: true,
            cursor: cursor
        });

        this.startDrag(DragType.RESIZE);
    }

    handleDragResize = event => {
        if (this.selectionHasLockedItems) {
            return;
        }

        const { selection } = this.state;
        const resizeAnchor = this.resizeAnchor;

        let preserveAspectRatio;
        const lockAspectRatio = selection.some(element => element.lockAspectRatio);
        const preserveAspectRatioOnCornerResize = selection.some(element => element.preserveAspectRatioOnCornerResize);

        if (lockAspectRatio || (preserveAspectRatioOnCornerResize && resizeAnchor.equalsAnyOf(AnchorType.TOP_RIGHT, AnchorType.TOP_LEFT, AnchorType.BOTTOM_LEFT, AnchorType.BOTTOM_RIGHT))) {
            preserveAspectRatio = true;
        } else {
            if (resizeAnchor.equalsAnyOf(AnchorType.LEFT, AnchorType.TOP, AnchorType.RIGHT, AnchorType.BOTTOM)) {
                preserveAspectRatio = false;
            } else {
                preserveAspectRatio = event.shiftKey;
            }
            if (selection.length === 1 && selection[0].preserveAspectRatio) {
                preserveAspectRatio = !preserveAspectRatio;
            }
        }

        const dragPosition = this.getPositionFromEvent(event);

        this.resizeSelection(dragPosition, preserveAspectRatio);

        const snapLines = [];
        if (!this.snapToGrid && !event.metaKey) {
            const selectionSnapLines = this.getSnapLines(selection, true);
            const destinationSnapLines = this.getSnapLines(this.getNotSelectedElements(), false);
            const { horizontalSnapLine, verticalSnapLine } = this.calcSnap(selectionSnapLines, destinationSnapLines);
            if (verticalSnapLine || horizontalSnapLine) {
                const selectionBounds = this.getElementsSelectionBounds(selection);
                let resizeDragPosition = selectionBounds.getPoint(resizeAnchor);

                // Altering current drag position to snap to the calced snap lines
                if (verticalSnapLine && verticalSnapLine.offset !== 0) {
                    const scaledOffset = verticalSnapLine.bindingPoint === SnapLineBindingPoint.CENTER ? verticalSnapLine.offset * 2 : verticalSnapLine.offset;
                    resizeDragPosition = resizeDragPosition.offset(scaledOffset, 0);
                    snapLines.push(verticalSnapLine);
                }
                if (horizontalSnapLine && horizontalSnapLine.offset !== 0) {
                    const scaledOffset = horizontalSnapLine.bindingPoint === SnapLineBindingPoint.CENTER ? horizontalSnapLine.offset * 2 : horizontalSnapLine.offset;
                    resizeDragPosition = resizeDragPosition.offset(0, scaledOffset);
                    snapLines.push(horizontalSnapLine);
                }

                this.resizeSelection(resizeDragPosition, preserveAspectRatio);
            }
        }

        this.hasPendingModelChanges = true;

        const modelWasReverted = this.refreshElement(false);
        if (!modelWasReverted) {
            this.setState({ snapLines });
        }
    }

    resizeSelection = (dragPosition, preserveAspectRatio) => {
        const { selection, dragCreateModel } = this.state;
        const resizeAnchor = this.resizeAnchor;
        const modelBeforeResize = this.modelBeforeResize;

        // Calculating selection before resize based on the saved model
        const selectionBeforeResize = this.getElementsSelectionBounds(
            modelBeforeResize.elements
                .filter(model => selection.some(element => element.model.id === model.id))
                .map(model => ({ model }))
        );

        let scalingAnchorPoint;
        let newWidth, newHeight;
        if (resizeAnchor === AnchorType.BOTTOM_RIGHT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.TOP_LEFT);
            newWidth = dragPosition.x - selectionBeforeResize.left;
            newHeight = dragPosition.y - selectionBeforeResize.top;
        } else if (resizeAnchor === AnchorType.BOTTOM) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.TOP);
            newHeight = dragPosition.y - selectionBeforeResize.top;
        } else if (resizeAnchor === AnchorType.RIGHT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.LEFT);
            newWidth = dragPosition.x - selectionBeforeResize.left;
        } else if (resizeAnchor === AnchorType.TOP_RIGHT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.BOTTOM_LEFT);
            newWidth = dragPosition.x - selectionBeforeResize.left;
            newHeight = selectionBeforeResize.top + selectionBeforeResize.height - dragPosition.y;
        } else if (resizeAnchor === AnchorType.TOP) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.BOTTOM);
            newHeight = selectionBeforeResize.top + selectionBeforeResize.height - dragPosition.y;
        } else if (resizeAnchor === AnchorType.TOP_LEFT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.BOTTOM_RIGHT);
            newWidth = selectionBeforeResize.left + selectionBeforeResize.width - dragPosition.x;
            newHeight = selectionBeforeResize.top + selectionBeforeResize.height - dragPosition.y;
        } else if (resizeAnchor === AnchorType.LEFT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.RIGHT);
            newWidth = selectionBeforeResize.left + selectionBeforeResize.width - dragPosition.x;
        } else if (resizeAnchor === AnchorType.BOTTOM_LEFT) {
            scalingAnchorPoint = selectionBeforeResize.getPoint(AnchorType.TOP_RIGHT);
            newWidth = selectionBeforeResize.left + selectionBeforeResize.width - dragPosition.x;
            newHeight = dragPosition.y - selectionBeforeResize.top;
        }

        let xScale = newWidth ? Math.max(newWidth / selectionBeforeResize.width, 0) : null;
        let yScale = newHeight ? Math.max(newHeight / selectionBeforeResize.height, 0) : null;

        if (preserveAspectRatio) {
            if (xScale && yScale) {
                xScale = yScale = Math.max(xScale, yScale);
            } else if (xScale) {
                yScale = xScale;
            } else if (yScale) {
                xScale = yScale;
            }
        }

        for (const element of selection) {
            const elementModelBeforeResize = modelBeforeResize.elements.find(model => model.id === element.model.id);

            const boundsBeforeResize = new geom.Rect(element.model.x, element.model.y, element.model.width, element.model.height);

            if (xScale) {
                element.model.x = scalingAnchorPoint.x + (elementModelBeforeResize.x - scalingAnchorPoint.x) * xScale;
                element.model.width = elementModelBeforeResize.width * xScale;

                if (this.snapToGrid) {
                    element.model.x += this.getSnapToGridOffset(element.model.x);
                    element.model.width += this.getSnapToGridOffset(element.model.x + element.model.width);
                }

                element.model.x = Math.round(element.model.x);
                element.model.y = Math.round(element.model.y);
            }
            if (yScale) {
                const yShift = (elementModelBeforeResize.y - scalingAnchorPoint.y) * yScale;
                element.model.y = scalingAnchorPoint.y + yShift;
                element.model.height = elementModelBeforeResize.height * yScale;

                if (this.snapToGrid) {
                    element.model.y += this.getSnapToGridOffset(element.model.y);
                    element.model.height += this.getSnapToGridOffset(element.model.y + element.model.height);
                }

                element.model.x = Math.round(element.model.x);
                element.model.y = Math.round(element.model.y);
            }

            if ((xScale || yScale) && !dragCreateModel) {
                // element.model.fitToText = false;
            }

            element.onResize(boundsBeforeResize);
        }
    }

    startDragCreate = event => {
        const { containerElement } = this.props;
        const { dragCreateModel } = this.state;

        const clickPosition = this.getPositionFromEvent(event);
        // Disabling drag processing until we generate new element
        this.setState({ dragType: null });

        // Create a new element at the clicked point using the dragCreateModel
        const newModel = {
            ...dragCreateModel,
            id: uuid(),
            x: clickPosition.x,
            y: clickPosition.y,
            width: dragCreateModel.width || 150,
            height: dragCreateModel.height || 150
        };
        containerElement.model.elements.push(newModel);

        this.hasPendingModelChanges = true;
        this.refreshCanvas()
            .then(() => {
                const newElement = this.getElements().find(element => element.model.id === newModel.id);
                // After refreshing canvas, select the new element and start drag resizing...
                this.setState({ selection: [newElement] });

                if (newModel.type === AuthoringElementType.PATH) {
                    this.setState({ editingElement: newElement });
                    _.defer(() => {
                        this.editorRef.current.designerRef.current.handleMouseDown(event, newModel.points[1]);
                    });
                } else {
                    this.startDrag(DragType.RESIZE);
                    this.onResizeStarted(event, AnchorType.BOTTOM_RIGHT);
                }
            });
    }

    calcSnap = (sourceSnapLines, destinationSnapLines) => {
        const snapLines = [];
        for (const sourceSnapLine of sourceSnapLines) {
            for (const destinationSnapLine of destinationSnapLines) {
                if (
                    sourceSnapLine.direction === destinationSnapLine.direction &&
                    sourceSnapLine.corridorBounds.intersects(destinationSnapLine.corridorBounds)
                ) {
                    // Enriching snap line with offset in order to reposition selected elements in future
                    // Also replacing bindingPoint with the binding point from the selection, will be used
                    // for calculating correct offset
                    const offset = sourceSnapLine.direction === SnapLineDirection.HORIZONTAL
                        ? destinationSnapLine.snapLineBounds.y - sourceSnapLine.snapLineBounds.y
                        : destinationSnapLine.snapLineBounds.x - sourceSnapLine.snapLineBounds.x;
                    const calculatedSnapLine = { ...destinationSnapLine, bindingPoint: sourceSnapLine.bindingPoint, offset };

                    // Cutting snap line
                    const emitterAndReceiverBounds = sourceSnapLine.sourceBounds.union(destinationSnapLine.sourceBounds);
                    calculatedSnapLine.snapLineBounds = calculatedSnapLine.snapLineBounds.intersection(emitterAndReceiverBounds);

                    snapLines.push(calculatedSnapLine);
                }
            }
        }

        if (snapLines.length === 0) {
            return {};
        }

        // We want to apply only the closest horizontal and vertical snap lines (one of each type)
        const sortedSnapLines = snapLines.sort(snapLine => snapLine.offset);
        const horizontalSnapLine = sortedSnapLines.find(snapLine => snapLine.direction === SnapLineDirection.HORIZONTAL);
        const verticalSnapLine = sortedSnapLines.find(snapLine => snapLine.direction === SnapLineDirection.VERTICAL);

        return { horizontalSnapLine, verticalSnapLine };
    }

    getSnapLines = (fromElements, unionElements) => {
        const { containerElement } = this.props;

        const snapLines = [];
        if (unionElements) {
            const bounds = this.getElementsSelectionBounds(fromElements);
            snapLines.push(...this.getSnapLinesForBounds(bounds));
        } else {
            for (const element of fromElements) {
                const bounds = this.getElementSelectionBounds(element);
                snapLines.push(...this.getSnapLinesForBounds(bounds));

                if (element === containerElement) {
                    const paddedBounds = this.getPaddedContainerBounds();
                    const paddedSnapLines = this.getSnapLinesForBounds(paddedBounds, false);
                    // Special casa to allow special rendering of container padded frame
                    paddedSnapLines.forEach(snapLine => snapLine.isContainerPadded = true);
                    snapLines.push(...paddedSnapLines);
                }
            }
        }

        return snapLines;
    }

    getSnapLinesForBounds = (bounds, includeCenterLines = true) => {
        const containerBounds = this.getContainerBounds();

        const snapLines = [
            {
                direction: SnapLineDirection.HORIZONTAL,
                bindingPoint: SnapLineBindingPoint.START,
                corridorBounds: new geom.Rect(containerBounds.left, bounds.top - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
                snapLineBounds: new geom.Rect(containerBounds.left, bounds.top, containerBounds.width, 0)
            },
            {
                direction: SnapLineDirection.HORIZONTAL,
                bindingPoint: SnapLineBindingPoint.CENTER,
                corridorBounds: new geom.Rect(containerBounds.left, bounds.centerV - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
                snapLineBounds: new geom.Rect(containerBounds.left, bounds.centerV, containerBounds.width, 0)
            },
            {
                direction: SnapLineDirection.HORIZONTAL,
                bindingPoint: SnapLineBindingPoint.END,
                corridorBounds: new geom.Rect(containerBounds.left, bounds.bottom - SNAP_TOLERANCE / 2, containerBounds.width, SNAP_TOLERANCE),
                snapLineBounds: new geom.Rect(containerBounds.left, bounds.bottom, containerBounds.width, 0)
            },
            {
                direction: SnapLineDirection.VERTICAL,
                bindingPoint: SnapLineBindingPoint.START,
                corridorBounds: new geom.Rect(bounds.left - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
                snapLineBounds: new geom.Rect(bounds.left, containerBounds.top, 0, containerBounds.height)
            },
            {
                direction: SnapLineDirection.VERTICAL,
                bindingPoint: SnapLineBindingPoint.CENTER,
                corridorBounds: new geom.Rect(bounds.centerH - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
                snapLineBounds: new geom.Rect(bounds.centerH, containerBounds.top, 0, containerBounds.height)
            },
            {
                direction: SnapLineDirection.VERTICAL,
                bindingPoint: SnapLineBindingPoint.END,
                corridorBounds: new geom.Rect(bounds.right - SNAP_TOLERANCE / 2, containerBounds.top, SNAP_TOLERANCE, containerBounds.height),
                snapLineBounds: new geom.Rect(bounds.right, containerBounds.top, 0, containerBounds.height)
            }
        ]
            // Adding source bounds to each snap line
            .map(snapLine => ({ ...snapLine, sourceBounds: bounds }));

        if (includeCenterLines) {
            return snapLines;
        }

        return snapLines.filter(snapLine => snapLine.bindingPoint !== SnapLineBindingPoint.CENTER);
    }

    onSelectionAction = action => {
        this.setState({ showContextMenu: false });

        switch (action) {
            case "bringToFront":
                return this.bringSelectionToFront();
            case "sendToBack":
                return this.sendSelectionToBack();
            case "cut":
                return this.copyToClipboard(true);
            case "copy":
                return this.copyToClipboard(false);
            case "copyStyles":
                return this.copyStyles();
            case "pasteStyles":
                return this.pasteStyles();
            case "delete":
                return this.removeSelectedElements();
            case "group":
                return this.groupSelection();
            case "ungroup":
                return this.ungroupSelection();
            case "lock":
                return this.lockSelection();
            case "unlock":
                return this.unlockSelection();
            case "align-left":
            case "align-center":
            case "align-right":
            case "align-top":
            case "align-middle":
            case "align-bottom":
                return this.alignSelection(action);
            case "select-all":
                return this.selectAll();
            case "distribute-horizontal":
            case "distribute-vertical":
                return this.distributeSelection(action);
        }
    }

    selectAll = () => {
        this.setState({ selection: this.getElements() });
    }

    copyToClipboard = cut => {
        let { selection } = this.state;

        if (selection.length > 0) {
            clipboardWrite({
                [ClipboardType.AUTHORING]: selection.map(element => element.model),
            });

            if (cut) {
                this.removeSelectedElements();
            }
        }
    }

    pasteFromClipboard = async data => {
        // Lock the slide first
        this.canvas.lockSlideForCollaborators(10);
        if (isObject(data)) {
            this.pasteElementsFromClipboard(data);
        } else if (isString(data)) {
            this.pasteTextFromClipboard(data);
        }
    }

    pasteAssetFromClipboard = async asset => {
        const { containerElement } = this.props;
        if (asset) {
            const bounds = new geom.Rect(0, 0, asset.get("w"), asset.get("h")).fitToSize(new geom.Size(this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT)).centerInRect(new geom.Rect(0, 0, this.canvas.CANVAS_WIDTH, this.canvas.CANVAS_HEIGHT));
            const model = {
                id: uuid(),
                type: AuthoringElementType.CONTENT,
                ...bounds.toXYObject(),
                element: {
                    content_value: asset.id,
                    content_type: AssetType.IMAGE
                }
            };
            containerElement.model.elements.push(model);
            await this.refreshCanvasAndSaveChanges();

            this.setState({ selection: [] });
        }
    }

    pasteElementsFromClipboard = (data, useContextMenuPosition = false) => {
        const { containerElement } = this.props;
        const { contextMenuPositionX, contextMenuPositionY } = this.state;

        if (data.length > 0) {
            // Unlocking pasted elements
            const newModels = data.map(model => ({ ...model, id: uuid(), isLocked: false }));

            const groupIdsMapping = {};
            newModels.forEach(model => {
                if (model.groupIds) {
                    const mappedGroupIds = [];
                    model.groupIds.forEach(groupId => {
                        if (!groupIdsMapping[groupId]) {
                            groupIdsMapping[groupId] = uuid();
                        }
                        mappedGroupIds.push(groupIdsMapping[groupId]);
                    });
                    model.groupIds = mappedGroupIds;
                }
            });

            if (useContextMenuPosition) {
                // Put pasted elements in the context menu position
                let copiedElementsBounds;
                newModels.forEach(model => {
                    const modelBounds = new geom.Rect(model.x, model.y, model.width, model.height);
                    if (!copiedElementsBounds) {
                        copiedElementsBounds = modelBounds;
                    } else {
                        copiedElementsBounds = copiedElementsBounds.union(modelBounds);
                    }
                });

                const newCenterPosition = this.getPositionFromEvent({ pageX: contextMenuPositionX, pageY: contextMenuPositionY });
                const offset = copiedElementsBounds.center.delta(newCenterPosition);
                newModels.forEach(model => {
                    model.x += offset.x;
                    model.y += offset.y;
                });
            } else {
                // Offset pasted elements by 10px
                newModels.forEach(model => {
                    model.x += 50;
                    model.y += 50;
                });
            }

            containerElement.model.elements.push(...newModels);

            this.refreshCanvasAndSaveChanges()
                .then(() => {
                    const newSelection = this.getElements().filter(element => newModels.some(({ id }) => element.model.id === id));
                    this.setState({ selection: newSelection });
                });
        }
    }

    pasteTextFromClipboard = async text => {
        const { containerElement } = this.props;

        const bounds = new geom.Rect(200, 200, this.canvas.CANVAS_WIDTH - 400, this.canvas.CANVAS_HEIGHT - 400);
        const model = {
            id: uuid(),
            type: AuthoringElementType.SHAPE,
            ...bounds.toXYObject(),
            fitToText: true,
            fill: "none",
            stroke: "none",
            text: {
                blocks: [{
                    id: uuid(),
                    type: AuthoringBlockType.TEXT,
                    textStyle: TextStyleType.BODY,
                    html: sanitizeHtmlText(text)
                }]
            }
        };
        containerElement.model.elements.push(model);
        this.refreshCanvasAndSaveChanges()
            .then(() => {
                this.setState({ selection: [] });
            });
    }

    copyStyles = () => {
        const { selection } = this.state;

        if (selection.length) {
            const container = selection[0];
            const model = container.element?.model || container.model;
            if (model) {
                let styles = {};
                for (const prop of COPIED_STYLES) {
                    if (prop in model) {
                        styles[prop] = model[prop];
                    }
                }

                // check for special copied data
                if (model.componentType === "TableFrame") {
                    styles.tableStyles = {};
                    for (const prop of TABLE_STYLES) {
                        if (prop in model.element) {
                            styles.tableStyles[prop] = model.element[prop];
                        }
                    }

                    // also, copy the leading column/row style
                    const [leadRow] = model.element.rows || [];
                    const [leadCol] = model.element.cols || [];
                    if (leadCol) {
                        styles.tableStyles.leadColStyle = leadCol.style;
                    }
                    if (leadRow) {
                        styles.tableStyles.leadRowStyle = leadRow.style;
                    }
                }

                clipboardWrite({
                    [ClipboardType.STYLES]: styles,
                });
            }
        }
    }

    pasteStyles = async () => {
        const { selection } = this.state;

        let styles = await clipboardRead([ClipboardType.STYLES]);
        if (styles) {
            for (const container of selection) {
                const model = container.element?.model || container.model;
                if (model) {
                    // Only copy over expected properties
                    for (const prop of COPIED_STYLES) {
                        if (prop in styles) {
                            model[prop] = styles[prop];
                        }
                    }

                    // copy table styles, if any
                    if (model.componentType === "TableFrame" && styles.tableStyles) {
                        for (const prop of TABLE_STYLES) {
                            if (prop in styles.tableStyles) {
                                model.element[prop] = styles.tableStyles[prop];
                            }
                        }

                        // set lead styles
                        if (styles.tableStyles.leadColStyle && model.element.cols[0]) {
                            model.element.cols[0].style = styles.tableStyles.leadColStyle;
                        }

                        if (styles.tableStyles.leadRowStyle && model.element.rows[0]) {
                            model.element.rows[0].style = styles.tableStyles.leadRowStyle;
                        }
                    }
                }
            }
            this.refreshCanvasAndSaveChanges();
        }
    }

    bringSelectionToFront = () => {
        const { containerElement } = this.props;
        const { selection } = this.state;

        for (const element of selection) {
            containerElement.model.elements.move(containerElement.model.elements.indexOf(element.model), containerElement.model.elements.length - 1);
        }

        this.refreshCanvasAndSaveChanges();
    }

    sendSelectionToBack = () => {
        const { containerElement } = this.props;
        const { selection } = this.state;

        for (const element of selection) {
            containerElement.model.elements.move(containerElement.model.elements.indexOf(element.model), 0);
        }

        this.refreshCanvasAndSaveChanges();
    }

    getSelectionGroups = () => {
        const { selection } = this.state;

        const containerGroups = {};
        for (const container of selection) {
            if (container.groupId) {
                if (!containerGroups[container.groupId]) {
                    containerGroups[container.groupId] = [container];
                } else {
                    containerGroups[container.groupId].push(container);
                }
            } else {
                containerGroups[uuid()] = [container];
            }
        }

        return Object.entries(containerGroups)
            .map(([groupId, containers]) => ({
                bounds: this.getElementsSelectionBounds(containers),
                containers,
                groupId
            }));
    }

    alignSelection = align => {
        const { selection } = this.state;

        const containerGroups = this.getSelectionGroups();
        const selectionBounds = this.getElementsSelectionBounds(selection);

        switch (align) {
            case "align-left":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.left - bounds.left;
                    for (const container of containers) {
                        container.model.x += shift;
                    }
                }
                break;
            case "align-center":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.centerH - bounds.centerH;
                    for (const container of containers) {
                        container.model.x += shift;
                    }
                }
                break;
            case "align-right":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.right - bounds.right;
                    for (const container of containers) {
                        container.model.x += shift;
                    }
                }
                break;
            case "align-top":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.top - bounds.top;
                    for (const container of containers) {
                        container.model.y += shift;
                    }
                }
                break;
            case "align-middle":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.centerV - bounds.centerV;
                    for (const container of containers) {
                        container.model.y += shift;
                    }
                }
                break;
            case "align-bottom":
                for (const { bounds, containers } of containerGroups) {
                    const shift = selectionBounds.bottom - bounds.bottom;
                    for (const container of containers) {
                        container.model.y += shift;
                    }
                }
                break;
        }

        this.refreshCanvasAndSaveChanges();
    }

    distributeSelection = direction => {
        const { selection } = this.state;

        const containerGroups = this.getSelectionGroups();
        const selectionBounds = this.getElementsSelectionBounds(selection);

        switch (direction) {
            case "distribute-horizontal":
                const hGap = (selectionBounds.width - _.sumBy(containerGroups, ({ bounds }) => bounds.width)) / (containerGroups.length - 1);
                let x = selectionBounds.left;
                for (const containerGroup of _.sortBy(containerGroups, ({ bounds }) => bounds.left)) {
                    const shift = x - containerGroup.bounds.left;
                    for (const container of containerGroup.containers) {
                        container.model.x += shift;
                    }
                    x += containerGroup.bounds.width + hGap;
                }
                break;
            case "distribute-vertical":
                const vGap = (selectionBounds.height - _.sumBy(containerGroups, ({ bounds }) => bounds.height)) / (containerGroups.length - 1);
                let y = selectionBounds.top;
                for (const containerGroup of _.sortBy(containerGroups, ({ bounds }) => bounds.top)) {
                    const shift = y - containerGroup.bounds.top;
                    for (const container of containerGroup.containers) {
                        container.model.y += shift;
                    }
                    y += containerGroup.bounds.height + vGap;
                }
                break;
        }

        this.refreshCanvasAndSaveChanges();
    }

    duplicateElementModels = selection => {
        const newModels = selection.map(element => ({ ..._.cloneDeep(element.model), id: uuid() })).reverse();

        const groupIdsMapping = {};
        newModels.forEach(model => {
            if (model.groupIds) {
                const mappedGroupIds = [];
                model.groupIds.forEach(groupId => {
                    if (!groupIdsMapping[groupId]) {
                        groupIdsMapping[groupId] = uuid();
                    }
                    mappedGroupIds.push(groupIdsMapping[groupId]);
                });
                model.groupIds = mappedGroupIds;
            }
        });

        return newModels;
    }

    duplicateSelection = async () => {
        const { selection } = this.state;
        const { containerElement } = this.props;

        const newModels = this.duplicateElementModels(selection);
        if (this.lastDraggedOffset) {
            for (let model of newModels) {
                model.x += this.lastDraggedOffset.x;
                model.y += this.lastDraggedOffset.y;
            }
        }
        containerElement.model.elements.push(...newModels);

        this.hasPendingModelChanges = true;
        this.refreshCanvasAndSaveChanges()
            .then(() => {
                const newSelection = this.getElements().filter(element => newModels.some(({ id }) => element.model.id === id));
                this.setState({ selection: newSelection });
            });
    }

    renderElementSelectionOutline = (elementOrGroup, idx) => {
        const { containerElement } = this.props;

        const { type } = elementOrGroup;
        let elementBounds;

        // appears to be a group
        if (elementOrGroup instanceof SelectionGroup) {
            const { elements } = elementOrGroup;
            elementBounds = this.getElementSelectionBounds(elements[0]);
            for (let i = 1; i < elements.length; i++) {
                elementBounds = elementBounds.union(this.getElementSelectionBounds(elements[i]));
            }
        } else {
            // appears to be a single element
            elementBounds = this.getElementSelectionBounds(elementOrGroup);
        }

        if (!elementBounds) {
            return;
        }

        const canvasScale = containerElement.canvas.getScale();

        elementBounds = elementBounds.multiply(canvasScale);

        const outline = {
            fill: "none",
            stroke: themeColors.ui_blue,
            strokeWidth: 1,
            strokeDasharray: "2 2"
        };

        let contents;
        switch (type) {
            case AuthoringElementType.SHAPE:
                contents = elementOrGroup.element.renderShape(elementOrGroup.model.shape, outline, elementBounds.zeroOffset());
                break;

            case AuthoringElementType.PATH:
                const path = new PolyLinePath();
                path.points = elementOrGroup.model.points.map(({ x, y }) => new geom.Point(x, y).multiply(canvasScale));
                contents = <path d={path.toPathData()} style={outline} />;
                break;

            default:
                const { width, height } = elementBounds;
                if (width && height) {
                    contents = <rect width={width} height={height} {...outline} />;
                }

                break;
        }

        if (contents) {
            return (
                <svg
                    key={idx}
                    width="100%" height="100%"
                    style={{
                        position: "absolute",
                        top: elementBounds.top,
                        left: elementBounds.left,
                        overflow: "visible"
                    }}>
                    {contents}
                </svg>
            );
        }
    }

    render() {
        const { containerElement } = this.props;
        const {
            selection,
            rolloverElements,
            snapLines,
            gridLines,
            isMouseDown,
            hasDragged,
            dragCreateModel,
            dragType,
            dragSelectionBounds,
            cursor,
            editingElement,
            showContextMenu,
            contextMenuPositionX,
            contextMenuPositionY,
            showGridLines,
            hideControlBar,
            selectionElement
        } = this.state;

        if (this.isCanvasLayouterGenerating) {
            return null;
        }

        const showDragSelection = dragType === DragType.SELECTION && isMouseDown;

        let showSelectionBox = true;
        if (editingElement) {
            showSelectionBox = false;
        }

        const containerBoundsOffset = this.getContainerBoundsOffset();

        const selectionBounds = this.getElementsSelectionBounds(selection);

        const canResizeHorizontally = !editingElement && !selection.some(element => element.childElement.restrictResize?.width);
        const canResizeVertically = !editingElement && !selection.some(element => element.childElement.restrictResize?.height);

        const canAlignAndDistribute = this.getSelectionGroups().length > 1 && !this.selectionHasLockedItems;

        const canvas = containerElement.canvas;
        const canvasScale = canvas.getScale();

        return (
            <Fragment>
                {!hideControlBar && <AuthoringControlBar
                    ref={this.authoringControlBarRef}
                    dragCreate={dragCreateModel}
                    snapToGrid={this.snapToGrid}
                    toggleSnapToGrid={this.toggleSnapToGrid}
                    containerElement={containerElement}
                    selectElement={this.selectElement}
                    setDragCreateModel={this.setDragCreateModel}
                    canGroup={this.canGroupSelection}
                    canUngroup={this.canUngroupSelection}
                    clearSelection={this.clearSelection}
                    refreshCanvasAndSaveChanges={this.refreshCanvasAndSaveChanges}
                    onAction={this.onSelectionAction}
                    selection={selection}
                />}
                <MuiThemeProvider theme={dialogTheme}>
                    <ContainerBox
                        className="authoring-layer"
                        ref={this.containerBoxRef}
                        style={{
                            cursor: cursor
                        }}
                        bounds={this.getContainerBounds().offset(containerBoundsOffset)}
                        canvasScale={canvasScale}
                        onContextMenu={this.handleEvent}
                    >
                        {/*{gridLines.map((gridLine, idx) => (*/}
                        {/*    <GridLine*/}
                        {/*        key={idx}*/}
                        {/*        opacity={showGridLines ? 1 : 0}*/}
                        {/*        bounds={gridLine.bounds}*/}
                        {/*        isHilited={gridLine.isHilited}*/}
                        {/*        canvasScale={canvasScale}*/}
                        {/*    />*/}
                        {/*))}*/}
                        {/* Container padded snap lines will de rendered as a frame, see below */}
                        {snapLines.filter(snapLine => !snapLine.isContainerPadded).map((snapLine, idx) => (
                            <SnapLine
                                key={idx}
                                bounds={snapLine.snapLineBounds}
                                canvasScale={canvasScale}
                            />
                        ))}
                        {/* Will be rendering the whole padded frame instead of separate padded snap lines */}
                        {snapLines.some(snapLine => snapLine.isContainerPadded) &&
                            <SnapLine
                                bounds={this.getPaddedContainerBounds()}
                                canvasScale={canvasScale}
                            />
                        }
                        {showDragSelection &&
                            <SelectionBox
                                bounds={dragSelectionBounds.normalize()}
                                canvasScale={canvasScale}
                            />}
                        {showSelectionBox && rolloverElements.map((element, idx) => this.renderElementSelectionOutline(element, idx))}
                        {selection.length > 0 &&
                            <SelectionUI
                                isLocked={this.selectionHasLockedItems}
                                isEditing={editingElement !== null}
                                canGroup={this.canGroupSelection}
                                canUngroup={this.canUngroupSelection}
                                canAlignAndDistribute={canAlignAndDistribute}
                                canCopyStyles={selection.length === 1}
                                bounds={selectionBounds}
                                onResizeStarted={this.onResizeStarted}
                                onRotateStarted={this.onRotateStarted}
                                onContextMenuAction={this.onSelectionAction}
                                canResizeHorizontally={canResizeHorizontally}
                                canResizeVertically={canResizeVertically}
                                showSelectionBox={showSelectionBox}
                                selection={selection}
                                canvasScale={canvasScale}
                            >
                                {selection.length > 0 && !hasDragged &&
                                    getEditorForSelection({
                                        ref: this.editorRef,
                                        isLocked: this.selectionHasLockedItems,
                                        selection,
                                        bounds: selectionBounds.multiply(this.canvas.canvasScale),
                                        allowMultipleBlocks: true,
                                        editingElement,
                                        refreshCanvasAndSaveChanges: this.refreshCanvasAndSaveChanges,
                                        refreshElement: this.refreshElement,
                                        isEditing: editingElement == selection[0],
                                        startEditing: () => this.startEditing(selection[0]),
                                        stopEditing: this.stopEditing,
                                        saveChanges: this.saveChanges,
                                        ungroupSelection: this.ungroupSelection,
                                        calcSnap: this.calcSnap,
                                        snapToGrid: this.snapToGrid,
                                        getSnapToGridOffset: this.getSnapToGridOffset,
                                        getSnapLines: this.getSnapLines,
                                        getElements: this.getElements,
                                        containerElement,
                                        drawSnapLines: snapLines => this.setState({ snapLines }),
                                        showGridLines: () => this.setState({ showGridLines: true }),
                                        hideGridLines: () => this.setState({ showGridLines: false }),
                                        selectionElement
                                    })
                                }
                            </SelectionUI>
                        }

                        {selection.length > 0 &&
                            <SelectionContextMenu
                                open={showContextMenu}
                                selection={selection}
                                contextMenuPosition={{ x: contextMenuPositionX, y: contextMenuPositionY }}
                                isLocked={this.selectionHasLockedItems}
                                allowDragMove={this.allowDragMove}
                                canGroup={this.canGroupSelection}
                                canUngroup={this.canUngroupSelection}
                                canAlignAndDistribute={canAlignAndDistribute}
                                canCopyStyles={selection.length === 1}
                                onContextMenuAction={this.onSelectionAction}
                                onClose={() => {
                                    this.setState({ showContextMenu: false });
                                }}
                                elementsUnderMouse={selection}
                                onSelectElement={this.selectElement}
                            />
                        }

                        {selection.length == 0 &&
                            <Menu
                                open={showContextMenu}
                                anchorReference="anchorPosition"
                                anchorPosition={{ left: contextMenuPositionX, top: contextMenuPositionY }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                onClose={() => this.setState({ showContextMenu: false })}
                            >
                                <MenuItem onMouseDown={() => this.onSelectionAction("select-all")}>
                                    <SelectAll />Select All
                                </MenuItem>
                            </Menu>
                        }
                    </ContainerBox>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

class SelectionGroup {
    constructor(elements) {
        this.elements = elements;
    }

    get groupId() {
        return this.elements[0]?.groupIds?.[0];
    }
}
